import { Routes } from "../../../navigation/routes";

export const DISCIPLINES_LIST = [
    {
        label: 'Cricket',
        value: 'cricket',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/cricket_4880955e9b.webp',
        link: Routes.cricketDiscipline
    },
    {
        label: 'Dance',
        value: 'dance',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/dance_6700c82279.webp',
        link: Routes.danceDiscipline
    },
    {
        label: 'Badminton',
        value: 'badminton',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/badminton_f3273a05ad.webp',
        link: Routes.badmintonDiscipline
    },
    {
        label: 'Arts',
        value: 'arts',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/arts_24a6c43bdb.webp',
        link: Routes.artsDiscipline
    },
    {
        label: 'Karate',
        value: 'karate',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/karate_29ea3fc160.webp',
        link: Routes.karateDiscipline
    },
    {
        label: 'Piano',
        value: 'piano',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/piano_dac4c48845.webp',
        link: Routes.pianoDiscipline
    },
    {
        label: 'Taekwondo',
        value: 'taekwondo',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/taekwondo_0037d0ea25.webp',
        link: Routes.taekwondoDiscipline
    },
    {
        label: 'Guitar',
        value: 'guitar',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/guitar_fab0e817b3.webp',
        link: Routes.guitarDiscipline
    },
    {
        label: 'Mixed Martial Arts',
        value: 'mma',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/mma_a5ab9a69de.webp',
        link: Routes.mmaDiscipline
    },
    {
        label: 'Painting',
        value: 'painting',
        img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/painting_e44ba59d07.webp',
        link: Routes.paintingDiscipline
    }
]