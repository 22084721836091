import React from 'react';
import Navbar from '../../../../components/landing-page-items/navbar';
import Footer from '../../../../components/landing-page-items/footer';
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../navigation/routes';
import { Helmet } from 'react-helmet-async';

const PaintingDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                   Painting & Drawing Classes in Bangalore - SpArts
                </title>
                <meta name="description" content="SpArts: Top Painting Academy in Bangalore. Explore painting and drawing classes with expert guidance. Perfect for beginners and advanced learners. Join today!"/>
                 <link rel="canonical" href={`https://students.sparts.app/discipline/painting`} />
            </Helmet>
            <Navbar />
            <div className="md:px-10 px-2">
                <header className="w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-[#e9b207]">
                    <video
                        src="https://sparts-bucket-qa.s3.amazonaws.com/private/Painting_Hero_banner_09ec881a06.webm"
                        autoPlay
                        muted
                        loop
                        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40"
                    />
                    <div className="text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg">
                        <h1 className="text-[#F9CA54] underline underline-offset-8">Painting </h1>
                        <div className="shadow-black">SpArts Painting Classes,</div>
                        <div className="shadow-black">Unleash Your Creativity</div>
                    </div>
                </header>

                <body className="bg-gray-50 text-gray-800 font-sans">
                    <div className="max-w-5xl mx-auto p-6">
                        <div className="text-center py-6">
                            <h1 className="text-4xl font-bold text-green-600">
                                SpArts Painting Classes,
                            </h1>
                            <p className="mt-2 text-lg">Unleash Your Creativity</p>
                        </div>

                        <section className="mb-8">
                            <p className="text-center text-gray-700 text-lg">
                                At SpArts Painting Academies, we provide a nurturing space where
                                artistic talents flourish. Whether you’re a budding artist or an
                                experienced painter, our certified teachers and structured
                                curriculum help you master the art of painting while fostering
                                creativity and self-expression.
                            </p>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-green-600 mb-4">
                                The Importance of Learning Painting
                            </h2>
                            <img
                                src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_6_f23813be56.webp"
                                alt="The Importance of Learning Painting"
                                className="w-full rounded-xl mb-6"
                            />

                            <div className="space-y-4">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Boosts Creativity
                                    </h3>
                                    <p className="text-gray-700">
                                        Painting encourages imagination and innovative thinking,
                                        essential skills for personal and academic growth.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Enhances Focus and Patience
                                    </h3>
                                    <p className="text-gray-700">
                                        The process of creating art teaches kids to concentrate and
                                        develop patience.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Fosters Self-Expression
                                    </h3>
                                    <p className="text-gray-700">
                                        Art allows to communicate emotions and ideas in a creative
                                        and fulfilling way.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-green-600 mb-4">
                                Why Choose SpArts Painting Academy?
                            </h2>
                            <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-green-600">
                                “Painting is just another way of keeping a diary.” – Pablo Picasso.
                            </blockquote>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Certified Teachers
                                    </h3>
                                    <p className="text-gray-700">
                                        Our instructors are certified professionals with years of
                                        experience in various painting techniques and styles. They
                                        guide students with personalized attention, helping them
                                        refine their artistic skills.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Comprehensive Curriculum
                                    </h3>
                                    <p className="text-gray-700">
                                        Our curriculum is thoughtfully designed to cover fundamental
                                        and advanced painting techniques, from color theory and
                                        brushwork to composition and style. It caters to beginners
                                        and advanced learners alike.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Pathways to Cultural Excellence
                                    </h3>
                                    <p className="text-gray-700">
                                        SpArts supports your artistic aspirations by providing
                                        opportunities for recognition. With IITM now offering seats
                                        in bachelor programs for students who excel in painting and
                                        other cultural activities, you can combine your artistic
                                        passion with academic success. Learn more about this in our
                                        <a
                                            className="text-blue-500"
                                            target="_blank"
                                            href={`${Routes.blogs}`}
                                        >
                                            {' '}
                                            blogs
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="text-center py-8 bg-green-600 text-white rounded-xl shadow-lg">
                            <h2 className="text-3xl font-bold mb-4">
                                Start Your Painting Journey Today!
                            </h2>
                            <p className="mb-6">
                                Whether you aim to explore your creative side or pursue painting as
                                a professional path, SpArts Painting Academy is here to inspire and
                                guide you.
                            </p>
                            <button
                                className="px-6 py-3 bg-white text-green-600 font-bold rounded-full shadow-md hover:bg-gray-100"
                                onClick={() => navigate(Routes.academies + '?disciplines=painting')}
                            >
                                Explore
                            </button>
                            <div className="mt-4">
                                <button
                                    className="px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400"
                                    onClick={() => navigate(Routes.SignUp)}
                                >
                                    Sign Up for a Free Trial Class
                                </button>
                                <button
                                    className="m-4 px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400"
                                    onClick={() => navigate(Routes.contactUs)}
                                >
                                    Contact Us
                                </button>
                            </div>
                        </section>
                    </div>
                </body>
            </div>
            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default PaintingDisciplinePage;
