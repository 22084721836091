import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../navigation/routes';
import Navbar from '../../../../components/landing-page-items/navbar';
import Footer from '../../../../components/landing-page-items/footer';
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button';
import { Helmet } from 'react-helmet-async';

const MmaDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                    Best Martial Arts coaching centre, Martial Arts Classes Training Academy in Bangalore - SpArts
                </title>
                <meta name="description" content="SpArts: Best Martial Arts coaching center in Bangalore. Expert Martial Arts training, personalized classes, and top-notch facilities for all age groups. Join now!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/mma`} />
            </Helmet>
            <Navbar />
            <div className="md:px-10 px-2">
                <header className="w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-[#776c5b]">
                    <video
                        src="https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Mixed_Martial_Arts_Classes_c4769943aa.webm"
                        autoPlay
                        muted
                        loop
                        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40"
                    />
                    <div className="text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg">
                        <h1 className="text-[#F9CA54] underline underline-offset-8">
                            Mixed Martial Arts
                        </h1>
                        <div className="shadow-black">SpArts MMA Academy,</div>
                        <div className="shadow-black">
                            Build Strength, Confidence, and Discipline
                        </div>
                    </div>
                </header>

                <body className="bg-gray-50 text-gray-800 font-sans">
                    <div className="max-w-5xl mx-auto p-6">
                        <div className="text-center py-6">
                            <h1 className="text-4xl font-bold text-green-600">
                                SpArts Mixed Martial Arts Classes
                            </h1>
                            <p className="mt-2 text-lg">
                                Build Strength, Confidence, and Discipline
                            </p>
                        </div>

                        <section className="mb-8">
                            <p className="text-center text-gray-700 text-lg">
                                At SpArts Mixed Martial Arts (MMA) Academies, we bring out the
                                warrior in you. With certified instructors and a structured
                                curriculum, we ensure that students of all ages and skill levels
                                master the art of MMA while developing physical fitness, mental
                                toughness, and self-defense skills.
                            </p>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-green-600 mb-4">
                                The Importance of Learning Mixed Martial Arts
                            </h2>
                            <img
                                src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_7_0d716cb2c0.webp"
                                alt="The Importance of Learning Mixed Martial Arts"
                                className="w-full rounded-xl mb-6"
                            />

                            <div className="space-y-4">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Improves Discipline and Focus
                                    </h3>
                                    <p className="text-gray-700">
                                        MMA teaches children the value of hard work, concentration,
                                        and respect.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Enhances Physical Fitness
                                    </h3>
                                    <p className="text-gray-700">
                                        The sport boosts strength, flexibility, and overall physical
                                        health.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Teaches Practical Self-Defense
                                    </h3>
                                    <p className="text-gray-700">
                                        Gain valuable self-defense skills that prepare you for
                                        real-life situations.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-green-600 mb-4">
                                Why Choose SpArts Mixed Martial Arts Academy?
                            </h2>
                            <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-green-600">
                                "I fear not the man who has practiced 10,000 kicks once, but I fear
                                the man who has practiced one kick 10,000 times." - Bruce Lee
                            </blockquote>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Certified Instructors
                                    </h3>
                                    <p className="text-gray-700">
                                        Our experienced and certified coaches provide expert
                                        guidance in various MMA techniques, ensuring safe and
                                        effective training for all students.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Comprehensive Curriculum
                                    </h3>
                                    <p className="text-gray-700">
                                        We offer a structured curriculum that covers striking,
                                        grappling, submission techniques, and self-defense,
                                        providing a complete MMA learning experience.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Focus on Personal Growth
                                    </h3>
                                    <p className="text-gray-700">
                                        At SpArts, MMA training is more than just a sport. It’s
                                        about building discipline, resilience, and confidence,
                                        preparing students for challenges both inside and outside
                                        the ring.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="text-center py-8 bg-green-600 text-white rounded-xl shadow-lg">
                            <h2 className="text-3xl font-bold mb-4">
                                Start Your MMA Journey Today!
                            </h2>
                            <p className="mb-6">
                                Whether you’re looking to improve fitness, learn self-defense, or
                                compete professionally, SpArts Mixed Martial Arts Academy is here to
                                support your goals.
                            </p>
                            <button
                                className="px-6 py-3 bg-white text-green-600 font-bold rounded-full shadow-md hover:bg-gray-100"
                                onClick={() => navigate(Routes.academies + '?disciplines=mma')}
                            >
                                Explore
                            </button>
                            <div className="mt-4">
                                <button
                                    className="px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400"
                                    onClick={() => navigate(Routes.SignUp)}
                                >
                                    Sign Up for a Free Trial Class
                                </button>
                                <button
                                    className="m-4 px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400"
                                    onClick={() => navigate(Routes.contactUs)}
                                >
                                    Contact Us
                                </button>
                            </div>
                        </section>
                    </div>
                </body>
            </div>
            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default MmaDisciplinePage;
