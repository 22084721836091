import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import { ACADEMY_FEATURED_QUERY, FEATURED_BLOGS_LIST } from '../../utils/constants/globals';
import { AcademyService } from '../../services/academy-service';
import { title } from 'process';
import { BlogService } from '../../services/blog-services';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

const HomePageWebViewModel = () => {

    const rows = [
        {
            images: ["/assets/splash/7.webp", "/assets/splash/8.webp", "/assets/splash/9.webp","/assets/splash/10.webp", "/assets/splash/11.webp", "/assets/splash/12.webp"],
            reverse: false,
            speed: 0.7,
        },
        {
            images: ["/assets/splash/1.webp", "/assets/splash/2.webp", "/assets/splash/3.webp","/assets/splash/4.webp", "/assets/splash/5.webp", "/assets/splash/6.webp"],
            reverse: true,
            speed: 0.3,
        },
        {
            images: ["/assets/splash/13.webp", "/assets/splash/14.webp", "/assets/splash/15.webp","/assets/splash/16.webp", "/assets/splash/17.webp", "/assets/splash/18.webp"],
            reverse: false,
            speed: 0.9,
        },
        {
            images: ["/assets/splash/19.webp", "/assets/splash/20.webp", "/assets/splash/21.webp","/assets/splash/22.webp", "/assets/splash/23.webp", "/assets/splash/24.webp"],
            reverse: true,
            speed: 0.1,
        },
        {
            images: ["/assets/splash/25.webp", "/assets/splash/26.webp", "/assets/splash/27.webp","/assets/splash/27.webp", "/assets/splash/29.webp", "/assets/splash/30.webp"],
            reverse: false,
            speed: 0.5,
        },
    ];

    const [showMoreDisciplinesModal, setShowMoreDisciplinesModal] = useState<number | null>(null);
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);

    const {
        isSuccess: isAcademiesToFeatureFetched,
        isLoading: isAcademiesToFeatureFetching,
        data: academiesToFeature,
    } = useQuery({
        queryKey: [ACADEMY_FEATURED_QUERY],
        queryFn: () => AcademyService.instance.getAllAcademiesToBeFeatured(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const {
        isLoading: isFeaturedBlogsFetching,
        data: featuredBlogs,
    } = useQuery({
        queryKey: [FEATURED_BLOGS_LIST],
        queryFn: () => BlogService.instance.getFeaturedBlogsList(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const handleShowMoreDisciplines = (index: number | null) => {
        setShowMoreDisciplinesModal(index);
    }

    const dummyData = [
        {
            img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/Are_you_ready_to_showcase_your_talent_page_0002_2f09af1c7d.jpg',
            title: 'Are you ready to showcase your talent?',
            subtitle: 'SpArts is here to help you!',
        },
        {
            img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/Are_you_ready_to_showcase_your_talent_page_0001_f28adcfaca.jpg',
            title: 'Are you ready to showcase your talent?',
            subtitle: 'SpArts is here to help you!',
        },
        {
            img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/Are_you_ready_to_showcase_your_talent_page_0004_e4a10a2140.jpg',
            title: 'Are you ready to showcase your talent?',
            subtitle: 'SpArts is here to help you!',
        },
        {
            img: 'https://sparts-bucket-qa.s3.amazonaws.com/private/Are_you_ready_to_showcase_your_talent_page_0003_bd11d4cfda.jpg',
            title: 'Are you ready to showcase your talent?',
            subtitle: 'SpArts is here to help you!',
        }
    ]

    const [activeFeaturedBlog, setActiveFeaturedBlog] = useState(0);

    useEffect(() => {
        const blogsData = featuredBlogs?.data?.data || [];

        if (blogsData.length) {
            const interval = setInterval(() => {
                setActiveFeaturedBlog((prev) => (prev + 1) % blogsData.length);
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [featuredBlogs?.data?.data]);

    return {
        isLoading: false,
        rows,
        academiesToFeature: academiesToFeature?.data?.data,
        handleShowMoreDisciplines,
        showMoreDisciplinesModal,
        dummyData,
        activeFeaturedBlog,
        featuredBlogs: featuredBlogs?.data?.data ?? [],
        setActiveFeaturedBlog,
        navigate,
        user,
    }
}

export default HomePageWebViewModel