import React from 'react'
import Navbar from '../../../../components/landing-page-items/navbar'
import Footer from '../../../../components/landing-page-items/footer'
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../navigation/routes'
import { Helmet } from 'react-helmet-async'

const ArtsDisciplinePage = () => {
  const navigate = useNavigate();

  return (
      <div>
        <Helmet>
            <title>
                   Painting & Drawing Classes in Bangalore, Fine Arts Academy in Bangalore - SpArts
                </title>
                <meta name="description" content="SpArts: Top Fine Arts Academy in Bangalore. Explore painting and drawing classes with expert guidance. Perfect for beginners and advanced learners. Join today!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/arts`} />
        </Helmet>
        <Navbar />
        <div className='md:px-10 px-2'>
            <div className='w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-green-900'>
                <video 
                    src='https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Arts_Classes_af9822070d.webm' 
                    autoPlay 
                    muted 
                    loop 
                    className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40'
                />
                <div className='text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg'>
                    <h1 className='text-[#F9CA54] underline underline-offset-8'>Arts</h1>
                    <div className='shadow-black'>SpArts Fine Arts Classes,</div>
                    <div className='shadow-black'>Where Inspiration Takes Shape!</div>
                </div>
            </div>
            <body className="bg-gray-50 text-gray-800 font-sans">
              <div className="max-w-5xl mx-auto p-6">
                  <div className="text-center py-6">
                      <h1 className="text-4xl font-bold text-blue-600">SpArts Fine Arts Classes</h1>
                      <p className="mt-2 text-lg">Nurturing creativity and passion for the arts.</p>
                  </div>

                  <section className="mb-8">
                      <p className="text-center text-gray-700 text-lg">
                          At SpArts Fine Arts Academy, we nurture creativity and passion for the arts, transforming raw talent into artistic brilliance. Our structured curriculum, diverse art forms, and experienced mentors ensure a holistic learning experience for every student. Whether you're a beginner or an experienced artist, SpArts is the place to unleash your artistic potential.
                      </p>
                  </section>

                  <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                      <h2 className="text-2xl font-semibold text-blue-600 mb-4">The Importance of Learning Arts</h2>
                      <img src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_1_e4de92eb5d.webp" alt="Learning Arts" className="w-full rounded-xl mb-6" />

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div>
                              <h3 className="text-xl font-bold text-gray-800 mb-2">For Kids:</h3>
                              <ul className="list-disc list-inside text-gray-700">
                                  <li>Develops creativity, critical thinking, and problem-solving skills.</li>
                                  <li>Enhances motor skills, focus, and emotional expression.</li>
                                  <li>Boosts self-esteem and fosters a sense of accomplishment.</li>
                              </ul>
                          </div>
                          <div>
                              <h3 className="text-xl font-bold text-gray-800 mb-2">For Adults:</h3>
                              <ul className="list-disc list-inside text-gray-700">
                                  <li>Provides a therapeutic outlet for stress and emotional well-being.</li>
                                  <li>Encourages lifelong learning and self-discovery.</li>
                                  <li>Builds a strong foundation for artistic hobbies or professional pursuits.</li>
                              </ul>
                          </div>
                      </div>
                  </section>

                  <section className="mb-8">
                      <h2 className="text-2xl font-semibold text-blue-600 mb-4">SpArts Arts Programs</h2>
                      <div className="space-y-4">
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Beginner Program:</h3>
                              <p className="text-gray-700">Introduces basic techniques, materials, and foundational skills in various art forms.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Intermediate Program:</h3>
                              <p className="text-gray-700">Focuses on refining techniques and exploring advanced methods, fostering creativity and individual expression.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Advanced Program:</h3>
                              <p className="text-gray-700">Tailored for aspiring professionals, this program includes portfolio development, art history, and participation in exhibitions.</p>
                          </div>
                      </div>
                  </section>

                  <section className="mb-8">
                      <h2 className="text-2xl font-semibold text-blue-600 mb-4">Why Choose SpArts Fine Arts Academies?</h2>
                      <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-blue-600">
                          “I found I could say things with color and shapes that I couldn't say any other way – things I had no words for.” &mdash; Georgia O'Keeffe
                      </blockquote>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Diverse Art Forms</h3>
                              <p className="text-gray-700">From painting and sketching to sculpture and digital art, SpArts offers a wide range of disciplines to inspire creativity and help students find their true artistic calling.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Structured Curriculum</h3>
                              <p className="text-gray-700">Our meticulously designed curriculum ensures that students master fundamental techniques while exploring their unique style.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Mentorship by Experts</h3>
                              <p className="text-gray-700">Learn from professional artists and experienced educators who bring a wealth of knowledge and a passion for teaching.</p>
                          </div>
                      </div>
                  </section>

                  <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                      <h2 className="text-2xl font-semibold text-blue-600 mb-4">Exciting Opportunities for Artists</h2>
                      <p className="text-gray-700">
                          In an exciting development, IIT Madras plans to introduce supernumerary seats for excellence in fine arts, creating new avenues for talented artists in academics and beyond. Read more about this opportunity in our blogs.
                      </p>
                  </section>

                  <section className="text-center py-8 bg-blue-600 text-white rounded-xl shadow-lg">
                      <h2 className="text-3xl font-bold mb-4">Join the SpArts Fine Arts Family</h2>
                      <p className="mb-6">Whether you dream of showcasing your work in galleries or simply want to explore your creative side, SpArts Fine Arts Academy is here to guide you every step of the way.</p>
                      <button onClick={() => navigate(Routes.academies + '?discipline=arts')} className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-gray-100">Get Started Today</button>
                  </section>
              </div>
          </body>
        </div>
        <Footer />
        <WhatsAppButton />
    </div>
  )
}

export default ArtsDisciplinePage