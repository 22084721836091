import React from 'react';
import Navbar from '../../../../components/landing-page-items/navbar';
import Footer from '../../../../components/landing-page-items/footer';
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../navigation/routes';
import { Helmet } from 'react-helmet-async';

const TaekwondoDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                    Taekwondo academy in Bangalore, Taekwondo Classes & Coaching Center - SpArts 
                </title>
                <meta name="description" content="SpArts: Premier Taekwondo academy in Bangalore. Expert coaching, tailored classes, and top facilities for all age groups. Master Taekwondo with us today!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/taekwondo`} />
            </Helmet>
            <Navbar />
            <div className="md:px-10 px-2">
                <header className="w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-[#5a0f14]">
                    <video
                        src="https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Taekwondo_Classes_27f0e75430.webm"
                        autoPlay
                        muted
                        loop
                        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40"
                    />
                    <div className="text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg">
                        <h1 className="text-[#F9CA54] underline underline-offset-8">Taekwondo</h1>
                        <div className="shadow-black">SpArts Taekwondo Classes,</div>
                        <div className="shadow-black">Empower Your Mind and Body</div>
                    </div>
                </header>

                <body className="bg-gray-50 text-gray-800 font-sans">
                    <div className="max-w-5xl mx-auto p-6">
                        <div className="text-center py-6">
                            <h1 className="text-4xl font-bold text-green-600">
                                SpArts Taekwondo Classes
                            </h1>
                            <p className="mt-2 text-lg">Empower Your Mind and Body</p>
                        </div>

                        <section className="mb-8">
                            <p className="text-center text-gray-700 text-lg">
                                At SpArts Taekwondo Academies, we are committed to developing
                                strong, confident individuals. Our programs are led by certified
                                coaches who are experts in their field, providing top-notch training
                                in a safe and supportive environment. Taekwondo is not just a
                                martial art; it’s a journey of personal growth, discipline, and
                                empowerment.
                            </p>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-green-600 mb-4">
                                The Importance of Learning Taekwondo
                            </h2>
                            <img
                                src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_5_3b7efd289f.webp"
                                alt="The Importance of Taekwondo"
                                className="w-full rounded-xl mb-6"
                            />

                            <div className="space-y-4">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Builds Discipline and Focus
                                    </h3>
                                    <p className="text-gray-700">
                                        Taekwondo instills a strong sense of discipline, helping
                                        children improve their concentration and work ethic.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Improves Coordination and Agility
                                    </h3>
                                    <p className="text-gray-700">
                                        Training enhances motor skills, balance, and overall
                                        physical development.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Teaches Respect and Confidence
                                    </h3>
                                    <p className="text-gray-700">
                                        Through the practice of martial arts, kids learn respect for
                                        themselves and others while gaining confidence in their
                                        abilities.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-green-600 mb-4">
                                Why Choose SpArts Taekwondo Academy?
                            </h2>
                            <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-green-600">
                                “Of those who start TaeKwonDo training, only about 5% stick with it
                                until they achieve the Black Belt Rank. Then perhaps 80% of those
                                who earn a Black stop there.” - Duk Sung Son
                            </blockquote>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Certified Coaches
                                    </h3>
                                    <p className="text-gray-700">
                                        Train with experienced and certified Taekwondo instructors
                                        who are dedicated to helping you achieve your goals, whether
                                        they’re fitness-related or competitive.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Holistic Fitness
                                    </h3>
                                    <p className="text-gray-700">
                                        Taekwondo is a full-body workout that builds physical
                                        strength, endurance, flexibility, and mental resilience. Our
                                        programs are designed to enhance both your physical and
                                        mental fitness.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Safe and Supportive Environment
                                    </h3>
                                    <p className="text-gray-700">
                                        We prioritize safety and inclusivity, ensuring that students
                                        of all ages and skill levels feel comfortable and motivated
                                        during their training.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="text-center py-8 bg-green-600 text-white rounded-xl shadow-lg">
                            <h2 className="text-3xl font-bold mb-4">
                                Join SpArts Taekwondo Academy Today!
                            </h2>
                            <p className="mb-6">
                                Whether you’re looking to develop discipline, stay fit, or prepare
                                for competitions, SpArts Taekwondo Academy is here to guide you
                                every step of the way.
                            </p>
                            <button
                                className="px-6 py-3 bg-white text-green-600 font-bold rounded-full shadow-md hover:bg-gray-100"
                                onClick={() =>
                                    navigate(Routes.academies + '?disciplines=taekwondo')
                                }
                            >
                                Explore
                            </button>
                            <div className="mt-4">
                                <button
                                    className="px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400"
                                    onClick={() => navigate(Routes.SignUp)}
                                >
                                    Sign Up for a Free Trial Class
                                </button>
                                <button
                                    className="m-4 px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400"
                                    onClick={() => navigate(Routes.contactUs)}
                                >
                                    Contact Us
                                </button>
                            </div>
                        </section>
                    </div>
                </body>
            </div>

            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default TaekwondoDisciplinePage;
