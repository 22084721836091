import React from 'react'
import Navbar from '../../../../components/landing-page-items/navbar'
import Footer from '../../../../components/landing-page-items/footer'
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button'
import { Routes } from '../../../../navigation/routes'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const KarateDisciplinePage = () => {
    const navigate = useNavigate();
  return (
      <div>
        <Helmet>
                <title>
                          Best Karate coaching centre, Karate Classes Training Academy in bangalore - SpArts
                      </title>
                      <meta name="description" content="SpArts: Best Karate coaching center in Bangalore. Expert karate training, personalized classes, and top-notch facilities for all age groups. Join now!"/>
                  <link rel="canonical" href={`https://students.sparts.app/discipline/karate`} />
        </Helmet>          
        <Navbar />
        <div className='md:px-10 px-2'>
            <div className='w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-black'>
                <video 
                    src='https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Karate_Classes_03e6aeebe0.webm' 
                    autoPlay 
                    muted 
                    loop 
                    className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40'
                />
                <div className='text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg'>
                    <h1 className='text-[#F9CA54] underline underline-offset-8'>Karate</h1>
                    <div className='shadow-black'>SpArts Karate Classes,</div>
                    <div className='shadow-black'>Unleash the Warrior Within!</div>
                </div>
            </div>
            <body className="bg-gray-50 text-gray-800 font-sans">
              <div className="max-w-5xl mx-auto p-6">
                  <div className="text-center py-6">
                      <h1 className="text-4xl font-bold text-blue-600">SpArts Karate Classes</h1>
                      <p className="mt-2 text-lg">Empowering individuals through the art of Karate.</p>
                  </div>

                  <section className="mb-8">
                      <p className="text-center text-gray-700 text-lg">
                          At SpArts, we believe that Karate is more than just a sport; it is a way of life. Our Karate academies are dedicated to nurturing discipline, enhancing physical and mental fitness, and empowering individuals with self-defense skills. Led by certified black belt Karate Sensei, we provide a world-className training experience tailored to all age groups and skill levels.
                      </p>
                  </section>

                  <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                      <h2 className="text-2xl font-semibold text-blue-600 mb-4">The Importance of Learning Karate</h2>
                      <img src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_1_1_09e2c161af.webp" alt="Benefits of Karate" className="w-full rounded-xl mb-6"/>

                      <div className="space-y-4">
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Builds Confidence and Leadership</h3>
                              <p className="text-gray-700">Karate empowers individuals to believe in themselves and take on challenges with courage. It fosters leadership skills, teamwork, and a sense of responsibility.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Promotes Healthy Living</h3>
                              <p className="text-gray-700">Regular Karate practice encourages a healthy lifestyle by combining physical activity with mental discipline, reducing the risk of lifestyle-related ailments.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Develops Emotional Resilience</h3>
                              <p className="text-gray-700">Karate teaches students to handle pressure and bounce back from setbacks. This emotional resilience is invaluable in personal and professional life.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Cultural Enrichment</h3>
                              <p className="text-gray-700">By practicing Karate, students embrace a rich tradition that emphasizes respect, honor, and perseverance.</p>
                          </div>
                      </div>
                  </section>

                  <section className="mb-8">
                      <h2 className="text-2xl font-semibold text-blue-600 mb-4">Why Choose SpArts Karate?</h2>
                      <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-blue-600">
                          “The ultimate aim of karate lies not in victory nor defeat, but in the perfection of character.” &mdash; Gichin Funakoshi
                      </blockquote>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Discipline That Transforms Lives</h3>
                              <p className="text-gray-700">Karate instills a sense of discipline that extends beyond the dojo. From mastering techniques to adhering to training schedules, our students develop habits that foster success in every aspect of life.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Physical and Mental Fitness</h3>
                              <p className="text-gray-700">Karate training is a holistic workout that enhances flexibility, strength, stamina, and balance. It also sharpens the mind by improving focus, resilience, and stress management.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Self-Defense Skills</h3>
                              <p className="text-gray-700">Learn effective techniques to protect yourself in real-world situations. Our self-defense training is practical, empowering, and instills confidence in every student.</p>
                          </div>
                          <div className="bg-white rounded-xl shadow-md p-4">
                              <h3 className="text-lg font-bold text-gray-800">Certified Black Belt Sensei</h3>
                              <p className="text-gray-700">Our instructors are certified black belt Karate Sensei with years of experience. They bring unparalleled expertise and passion to ensure every student thrives in a safe and supportive environment.</p>
                          </div>
                      </div>
                  </section>

                  <section className="text-center py-8 bg-blue-600 text-white rounded-xl shadow-lg">
                      <h2 className="text-3xl font-bold mb-4">Start Your Karate Journey Today!</h2>
                      <p className="mb-6">Whether you are a beginner or looking to refine your skills, SpArts Karate Academy offers programs for all levels. Join our community and experience the transformative power of Karate.</p>
                      <button onClick={() => navigate(Routes.academies + '?discipline=karate')} className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-gray-100">Enroll Now</button>
                      <div className="mt-4">
                          <button onClick={() => navigate(Routes.SignUp)} className="px-6 py-3 bg-blue-500 text-white font-bold rounded-full shadow-md hover:bg-blue-400">Sign Up for a Free Trial ClassName</button>
                          <button onClick={() => navigate(Routes.contactUs)} className="ml-4 px-6 py-3 bg-blue-500 text-white font-bold rounded-full shadow-md hover:bg-blue-400">Contact Us</button>
                      </div>
                  </section>
              </div>
          </body>
        </div>
        <Footer />
        <WhatsAppButton />
    </div>
  )
}

export default KarateDisciplinePage