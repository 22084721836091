import React, { useState } from 'react'
import { DISCIPLINES_LIST } from './data'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/bundle';
import "swiper/css/autoplay";
import { Swiper as SwiperType } from 'swiper';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../navigation/routes';

const HomePageDisciplineCarousal = () => {
    const [swiperInstance, setSwiperInstance] = useState<any>(null);
    const navigate = useNavigate();

    const breakpoints = {
        768: {
            slidesPerView: 6, 
        },
        
        320: {
            slidesPerView: 3,
        },
    };

    return (
        <div>
            <Swiper
                slidesPerView={6}
                spaceBetween={10}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay]}
                breakpoints={breakpoints}
                onSwiper={(swiper: SwiperType) => setSwiperInstance(swiper)}
                className="mySwiper"
                style={{
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        content: "''",
                        zIndex: 99,
                        display: 'block',
                        background: 'white',
                        width: `100%`,
                        height: '120px',
                        position: 'absolute',
                        borderRadius: '30%',
                        left: 0,
                        right: 0,
                        top: '-90px',
                        // left: `calc(-1 * 60px)`,
                        // right: `calc(-1 * 60px)`,
                        // top: `calc(-0.7 * 120px)`,
                    }}
                />
                <div
                    style={{
                        content: "''",
                        zIndex: 99,
                        display: 'block',
                        background: 'white',
                        width: `100%`,
                        height: '120px',
                        position: 'absolute',
                        borderRadius: '30%',
                        left: 0,
                        right: 0,
                        bottom: '-90px',
                        // left: `calc(-1 * 60px)`,
                        // right: `calc(-1 * 60px)`,
                        // bottom: `calc(-0.7 * 120px)`,
                    }}
                />
                {DISCIPLINES_LIST.map((discipline, index) => (
                    <SwiperSlide key={discipline.value} className='cursor-pointer' onClick={() => navigate(discipline.link)}>
                        <img alt='sports and arts discipline' src={discipline.img} className='w-full h-[360px] object-cover' loading='lazy' />
                        <div 
                            className='-translate-y-[40px] text-xl font-semibold text-white bg-black bg-opacity-50 backdrop-blur-md p-1 mx-4 bottom-0 left-0 right-0 absolute'
                        >
                            {discipline.label}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default HomePageDisciplineCarousal

