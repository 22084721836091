import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Navigation, FreeMode, Autoplay, EffectCoverflow } from 'swiper/modules';
import { testimonialsData } from './data';
import { Swiper as SwiperType } from 'swiper';
import { useState } from 'react';

interface ITestimonial {
    title: string;
    summary: string;
    img: string;
    subtitle:string;
}

const TestimonyCarousal = () => {
    const [swiperInstance, setSwiperInstance] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const breakpoints = {
        768: {
            slidesPerView: 3, 
        },
        
        320: {
            slidesPerView: 1,
        },
    };

    const borderRadiusOne = [
        '22% 78% 22% 78% / 75% 29% 71% 25%',
        '58% 42% 76% 24% / 75% 71% 29% 25%',
        '61% 39% 83% 17% / 75% 33% 67% 25%'
    ]

    const borderRadiusTwo = [
        '31% 69% 37% 63% / 75% 53% 47% 25%',
        '21% 79% 55% 45% / 75% 33% 67% 25%',
        '47% 53% 83% 17% / 59% 76% 24% 41%'
    ]

    return (
        <div className='pb-20 md:pb-8'>
             <div className='flex gap-4 md:px-20 overflow-visible'>
                <div>
                    <img 
                        onClick={() => {
                            if (swiperInstance) {
                                swiperInstance.slidePrev();
                            }
                        }}
                        className='w-10 rotate-180 cursor-pointer' 
                        src='/assets/images/arrow-right.png' 
                        alt='arrow'
                    />
                </div>
                <div className='flex-grow flex flex-col items-center justify-center gap-6'>
                    <div className='text-lg md:text-3xl font-semibold text-center'>Voices That Inspire Confidence in What We Do</div>
                    <div className='flex gap-4 items-center justify-center'>
                        {testimonialsData.map((_, idx) => (
                            <div 
                                key={idx} 
                                className={`h-3 w-3 border-4 rounded-full ${activeIndex === idx ? 'border-[#4285F4] scale-105' : 'border-[#020a17]'}`}
                            >
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <img 
                        onClick={() => {
                            if (swiperInstance) {
                                swiperInstance.slideNext();
                            }
                        }}
                        className='w-10 cursor-pointer' 
                        src='/assets/images/arrow-right.png' 
                        alt='arrow'
                    />
                </div>
            </div>
            <div className='flex items-center justify-center w-full md:block md:w-auto'>
                <div className='py-16 md:p-20 overflow-visible h-[320px] w-[320px] md:h-auto md:w-auto'>
                    <Swiper
                        modules={[EffectCoverflow, Navigation, FreeMode, Autoplay]}
                        autoplay={true}
                        spaceBetween={40}
                        centeredSlides={true}
                        slidesPerView={3}
                        breakpoints={breakpoints}
                        loop={true}
                        onSwiper={setSwiperInstance}
                        className='!overflow-visible'
                        onSlideChange={(swiper: SwiperType) => setActiveIndex((swiper.realIndex))}
                    >
                        {
                            testimonialsData && testimonialsData.map((d: ITestimonial, index: number) => {
                                return (
                                    <SwiperSlide
                                        key={index}
                                        className={`${activeIndex === index ? 'scale-105 z-10' : '!scale-75 opacity-80'} transition-all duration-300 ease-in-out `}
                                    >
                                        <div 
                                            className={`bg-[#020a17] h-[320px] w-[320px] md:h-[400px] md:w-[400px] relative overflow-visible`}
                                            style={{borderRadius: borderRadiusOne[index % 3]}}
                                        >
                                            <div className='absolute top-0 left-0 w-full h-full bg-white rotate-6 shadow-lg shadow-gray-500 p-12 overflow-visible' style={{borderRadius: borderRadiusTwo[index % 3]}}>
                                                <img alt='reviewer' src={d.img} className='h-16 md:h-24 w-16 md:w-24 rounded-full absolute top-0 left-0 right-0 mx-auto -mt-8' />
                                                <div className='-rotate-6 flex flex-col items-center justify-center h-full w-full'>
                                                    <div className='text-lg md:text-4xl'>{d.title}</div>
                                                    <div className='text-sm md:text-xl text-slate-500'>{d.subtitle}</div>
                                                    <div className='text-xs md:text-sm text-slate-500 text-center mt-2'>{d.summary}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default TestimonyCarousal;
