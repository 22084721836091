import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../../components/landing-page-items/navbar';
import { Routes } from '../../../../navigation/routes';
import Footer from '../../../../components/landing-page-items/footer';
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button';
import { Helmet } from 'react-helmet-async';

const BadmintonDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                    Best Badminton Coaching Centre, Badminton Training Academy in Bangalore - SpArts
                </title>
                <meta name="description" content="Join SpArts, the best Badminton coaching center in Bangalore. Expert Badminton training, world-class facilities, and personalized coaching to boost your Badminton skills!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/badminton`} />
            </Helmet>
            <Navbar />
            <div className="md:px-10 px-2">
                <header className="w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-[#4e9fbc]">
                    <video
                        src="https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Badminton_classes_c357e12a02.webm"
                        autoPlay
                        muted
                        loop
                        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40"
                    />
                    <div className="text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg">
                        <h1 className="text-[#F9CA54] underline underline-offset-8">Badminton</h1>
                        <div className="shadow-black">SpArts Badminton Classes,</div>
                        <div className="shadow-black">Smash Your Way to Success</div>
                    </div>
                </header>

                <body className="bg-gray-50 text-gray-800 font-sans">
                    <div className="max-w-5xl mx-auto p-6">
                        <div className="text-center py-6">
                            <h1 className="text-4xl font-bold text-blue-600">
                                SpArts Badminton Classes
                            </h1>
                            <p className="mt-2 text-lg">Smash Your Way to Success</p>
                        </div>

                        <section className="mb-8">
                            <p className="text-center text-gray-700 text-lg">
                                At SpArts Badminton Academies, we believe in nurturing talent and
                                building champions. Our academies offer professional training from
                                National players, world-class infrastructure, and coaching tailored
                                for all age groups and skill levels. Whether you’re a beginner or an
                                aspiring pro, SpArts has everything you need to excel.
                            </p>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                                The Importance of Playing Badminton
                            </h2>
                            <img
                                src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_3_98652b772a.webp"
                                alt="Benefits of Badminton"
                                className="w-full rounded-xl mb-6"
                            />

                            <div className="space-y-4">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Improves Fitness
                                    </h3>
                                    <p className="text-gray-700">
                                        Badminton enhances cardiovascular health, builds muscle
                                        strength, and increases agility and coordination
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Boosts Mental Sharpness
                                    </h3>
                                    <p className="text-gray-700">
                                        The sport improves reflexes, focus, and decision-making
                                        skills.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Promotes Social Interaction
                                    </h3>
                                    <p className="text-gray-700">
                                        Playing badminton fosters teamwork and builds relationships,
                                        making it enjoyable for both recreational and competitive
                                        players.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                                Why Choose SpArts Badminton Academies?
                            </h2>
                            <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-blue-600">
                                "A champion has to be disciplined by practicing regularly in the
                                right manner, respect coaches and most importantly ought to have
                                self-belief." &mdash; Saina Nehwal
                            </blockquote>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Training from National Players
                                    </h3>
                                    <p className="text-gray-700">
                                        Learn from the best! Our coaches are National-level players
                                        who bring unmatched expertise, guiding you every step of the
                                        way to achieve your goals.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        World-Class Infrastructure
                                    </h3>
                                    <p className="text-gray-700">
                                        Play on state-of-the-art courts designed to meet
                                        international standards. Our facilities ensure a premium
                                        training experience for every student.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Programs for All Skill Levels
                                    </h3>
                                    <p className="text-gray-700">
                                        We offer tailored coaching for kids and adults, beginners to
                                        advanced players, ensuring that everyone progresses at their
                                        own pace.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                                Pathways to Sports Excellence
                            </h2>
                            <p className="text-gray-700">
                                With IITM now offering seats in bachelor programs for students who
                                excel in sports like Badminton and clear JEE, your training can lead
                                to academic opportunities.
                                <a
                                    className="text-blue-500"
                                    target="_blank"
                                    href={`${Routes.blogs}`}
                                >
                                    {' '}
                                    Learn more about this in our blogs.
                                </a>
                            </p>
                        </section>

                        <div className="relative mx-auto bg-[#9adaf1] rounded-lg shadow-lg p-6">
                            <div className="text-center">
                                <h2 className="text-3xl font-bold text-blue-600">Did You Know?</h2>
                            </div>

                            <ul className="text-gray-700 mt-4 list-inside space-y-2">
                                <li>
                                    Over 220 million people worldwide enjoy badminton for
                                    recreation, making it one of the most popular sports.
                                </li>
                                <li>
                                    Thousands participate in tournaments at local, district, and
                                    state levels annually, showcasing their skills.
                                </li>
                                <li>
                                    Only a select few advance to national and international
                                    competitions, representing their country on the global stage.
                                </li>
                                <li>
                                    SpArts provides the training and support to help you reach these
                                    heights.
                                </li>
                            </ul>
                        </div>

                        <section className="text-center py-8 bg-blue-600 text-white rounded-xl shadow-lg mt-6 p-2">
                            <h2 className="text-3xl font-bold mb-4">
                                Start Your Badminton Journey Today!
                            </h2>
                            <p className="mb-6">
                                Whether you aim to stay fit, compete in local tournaments, or dream
                                of playing at the national level, SpArts Badminton Academy is here
                                to guide you.
                            </p>
                            <button
                                className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-gray-100"
                                onClick={() =>
                                    navigate(Routes.academies + '?disciplines=badminton')
                                }
                            >
                                Explore
                            </button>
                            <div className="mt-4">
                                <button
                                    className="px-6 py-3 bg-blue-500 text-white font-bold rounded-full shadow-md hover:bg-blue-400"
                                    onClick={() => navigate(Routes.SignUp)}
                                >
                                    Sign Up for a Free Trial Class
                                </button>
                                <button
                                    className="m-4 px-6 py-3 bg-blue-500 text-white font-bold rounded-full shadow-md hover:bg-blue-400"
                                    onClick={() => navigate(Routes.contactUs)}
                                >
                                    Contact Us
                                </button>
                            </div>
                        </section>
                    </div>
                </body>
            </div>
            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default BadmintonDisciplinePage;
