import React from 'react';
import { Capacitor } from '@capacitor/core';
import HomePageNative from '../../../pages/home-page-native';
import HomePageWeb from '../../../pages/public/home-page-web';

const DefaultHome: React.FC = () => {
  const isNative = Capacitor.isNativePlatform();

  return (
      <div>
          {isNative ? <HomePageNative /> : <HomePageWeb />}
      </div>
  );
};

export default DefaultHome;