import React, { useState } from 'react';
import styles from "./styles.module.css"
import Footer from '../../components/landing-page-items/footer';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/landing-page-items/navbar';
import WhatsAppButton from '../../components/common/whatsapp-chat-button';
import ActivityFinder from '../../components/landing-page-items/activity-finder';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
const Aboutpage: React.FC = () => {

    const [isActivityFinderOpen, setIsActivityFinderOpen] = useState(false);
    const navigate = useNavigate();
    
    const handleToggleActivityFinder = () => {
        setIsActivityFinderOpen(prev => !prev);
    }
    
    return (
        <div className={styles.container}>
            <Helmet>
                <title>About SpArts | Making the next generation fitter</title>
                <meta name='description' content='Discover SpArts! Our mission is to support students growth in sports and creative arts through innovative tools. Learn how we use technology to make the next generation fitter' />
                <link rel="canonical" href="https://students.sparts.app/about-us" />
            </Helmet>
            {isActivityFinderOpen &&
                    <ActivityFinder
                        handleToggleActivityFinder={handleToggleActivityFinder}
                    />
            }
            <Navbar />
            <div className='flex sm:flex-row flex-col gap-8'>
                <video id="bannerVideo" autoPlay muted className='sm:w-[55%] w-full sm:ml-[10%]'>
                    <source src="https://sparts-bucket-qa.s3.amazonaws.com/private/product_explainer_copy_056f4f60d3.mp4" type="video/mp4" />
                </video>
                <div className='grid gap-4'>
                    <p className='text-2xl font-bold text-[#FEC536] sm:text-left text-center'>What do we do</p>
                    <p className='sm:text-4xl text-2xl sm:leading-[50px] leading-8 tracking-widest p-4 w-full sm:text-left text-center'>We make next generation fitter by making
                        <span className='font-semibold italic'> quality</span> sports and creative arts education
                        <span className='font-semibold italic'> accessible</span> </p>
                    <div className='flex gap-4 items-center px-4'>
                        <img src='./assets/images/Sports.png' alt='badminton' className='h-24'/>
                        <div>
                            <h2 className='text-2xl'>Sports Education</h2>
                            <p className='w-4/5'>Holistic and well-structured approach to learning sports, prioritizing Long-term Athlete Development over creating pressure to excel in every competition.</p>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center px-4'>
                        <img src='./assets/images/Orchestra.png' alt='guitar' className='h-24'/>
                        <div>
                            <h2 className='text-2xl'>Creative Arts Education</h2>
                            <p className='w-4/5'>Focus on increasing calmness, decreasing anxiety and restlessness. Give a hobby that can be cherished for life</p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="flex flex-col justify-center items-center gap-2">
                    <h2 className="text-2xl font-bold tracking-widest px-4 text-center">Preparing Learners to Achieve Success</h2>
                    <img src="/assets/images/Vector.png" alt="underline" className="h-6" />
                </div>
                <div>
                    <div className="flex flex-col-reverse lg:flex-row justify-center items-center lg:gap-[5%] mb-16">
                        <div className="w-full lg:w-4/12 text-center lg:text-left">
                            <h1 className="font-semibold text-2xl tracking-wider">Identifying the right activity</h1>
                            <hr className="w-3/5 h-1 bg-[#FEC536] mx-auto lg:mx-0" />
                            <p className="text-lg lg:text-xl text-[#848383] mt-4 px-4 lg:px-0">
                                Never can 1 size fit all. You may have been a swimming champion but your little one may prefer running on the land. Let us help you find the age-appropriate, interest-based activity for your little one.
                            </p>
                            <button className="w-48 lg:w-56 h-12 bg-[#FEC536] rounded-xl mt-4" onClick={handleToggleActivityFinder}>Activity Finder</button>
                        </div>
                        <img src="assets/images/littlegirl.png" alt="girl" className="w-4/5 lg:w-auto mb-6 lg:mb-0" />
                    </div>
                    
                    <div className="flex flex-col lg:flex-row justify-center items-center lg:gap-[5%] my-16">
                        <img src="assets/images/drawing.png" alt="drawing" className="w-4/5 lg:w-auto mb-6 lg:mb-0" />
                        <div className="w-full lg:w-4/12 text-center lg:text-left">
                            <h1 className="font-semibold text-2xl tracking-wider">Enjoy Learning with SpArts Verified Academies</h1>
                            <hr className="w-3/5 h-1 bg-[#FEC536] mx-auto lg:mx-0" />
                            <p className="text-lg lg:text-xl text-[#848383] mt-4 px-4 lg:px-0">
                                We only partner with the best activity centres in the town. All SpArts academies are personally vetted by the founding team and have all the amenities and facilities as shown on our website.
                            </p>
                            <button className="w-48 lg:w-56 h-12 bg-[#FEC536] rounded-xl mt-4" onClick={() => navigate(Routes.academies)}>Explore academies</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col text-center gap-12">
                <h2 className="text-2xl font-bold tracking-widest text-[#FEC536] m-0">The Founding Team</h2>
                <h2 className="text-xl md:text-2xl font-bold tracking-widest text-[#9F9E9E] m-0 px-4 md:px-0">
                    Our founders bring in decades of experience in sales, marketing, product, and building teams.
                </h2>
                <div className="flex flex-col md:flex-row justify-center gap-8 md:gap-20">
                    <div className="w-full md:w-1/3 flex flex-col items-center">
                        <div>
                            <img src="assets/images/mayur.jpeg" alt="Mayur" className="w-36 md:w-[200px]" />
                            <p className="font-bold mt-4">Mayur Borsaikia</p>
                        </div>
                        <p className="text-sm md:text-base px-6 md:px-8 mt-4">
                            Mayur Borsaikia, co-founder of SpArts, brings a wealth of experience from leading organizations such as Oyo, Spinny, Swiggy, and Meesho. 
                            An alumnus of IIM Bangalore and NIT Warangal, he combines strategic vision with a passion for transforming sports and arts education.
                        </p>
                    </div>
        
                    <div className="w-full md:w-1/3 flex flex-col items-center">
                        <div>
                            <img src="assets/images/daisy.jpeg" alt="Daisy" className="w-36 md:w-[200px]" />
                            <p className="font-bold mt-4">Daisy Priya</p>
                        </div>
                        <p className="text-sm md:text-base px-6 md:px-8 mt-4">
                            Daisy Priya, co-founder of SpArts, has an extensive background with NTPC, IBM, Idea Cellular, and Tracxn Technologies. 
                            An alumna of IIM Bangalore and NIT Jalandhar, she leads Technology and Product at SpArts, driving innovation and user-centric solutions.
                        </p>
                    </div>
                </div>
            </div>

            <div>
    <div className="flex flex-col justify-center items-center gap-2">
        <h2 className="text-2xl font-bold tracking-widest">Why it works</h2>
        <img src="/assets/images/Vector.png" alt="underline" className="h-6" />
    </div>
    <div className="flex flex-col md:flex-row gap-8 justify-center items-center mt-4">
        {/* Card 1 */}
        <div className="w-full md:w-60 flex flex-col items-center text-center">
            <img src="/assets/images/learning.png" alt="learning" className="w-24 md:w-auto" />
            <p className="font-semibold mt-4">Student focussed learning</p>
            <p className="mt-2">
                Students practice at their own pace, learning both in the academies and outside the class as well.
            </p>
        </div>
        {/* Card 2 */}
        <div className="w-full md:w-60 flex flex-col items-center text-center">
            <img src="/assets/images/academies.png" alt="academies" className="w-24 md:w-auto" />
            <p className="font-semibold mt-4">Trusted academies</p>
            <p className="mt-2">
                Curated by founders, SpArts academies are trusted places to learn and practice Sports and Creative Arts.
            </p>
        </div>
        {/* Card 3 */}
        <div className="w-full md:w-60 flex flex-col items-center text-center">
            <img src="/assets/images/tools.png" alt="tools" className="w-24 md:w-auto" />
            <p className="font-semibold mt-4">Tools to empower academies</p>
            <p className="mt-2">
                Academies can track and share the progress of the students with parents without spending much time and effort.
            </p>
        </div>
    </div>
            </div>

            <Footer />
            <WhatsAppButton />
        </div>
    );
};


export default Aboutpage;
