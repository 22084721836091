import React from 'react';
import Navbar from '../../../../components/landing-page-items/navbar';
import Footer from '../../../../components/landing-page-items/footer';
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button';
import { Routes } from '../../../../navigation/routes';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const GuitarDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                    Best Guitar Academy in Bangalore, Online Guitar Classes in Bangalore - SpArts
                </title>
                <meta name="description" content="SpArts: Best Guitar Academy in Bangalore. Learn guitar online or in-person with expert instructors. Personalized classes for all skill levels. Join now!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/guitar`} />
            </Helmet>
            <Navbar />
            <div className="md:px-10 px-2">
                <header className="w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-[#474031]">
                    <video
                        src="https://sparts-bucket-qa.s3.amazonaws.com/private/Guitar_Hero_Banner_e329485f32.webm"
                        autoPlay
                        muted
                        loop
                        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40"
                    />
                    <div className="text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg">
                        <h1 className="text-[#F9CA54] underline underline-offset-8">Guitar</h1>
                        <div className="shadow-black">SpArts Guitar Academy,</div>
                        <div className="shadow-black">Strum Your Way to Mastery</div>
                    </div>
                </header>

                <body className="bg-gray-50 text-gray-800 font-sans">
                    <div className="max-w-5xl mx-auto p-6">
                        <div className="text-center py-6">
                            <h1 className="text-4xl font-bold text-blue-600">
                                SpArts Guitar Academy
                            </h1>
                            <p className="mt-2 text-lg">Strum Your Way to Mastery</p>
                        </div>

                        <section className="mb-8">
                            <p className="text-center text-gray-700 text-lg">
                                At SpArts Guitar Academies, we transform your passion for music into
                                a lifelong skill. Whether you are a beginner or an advanced
                                guitarist, our certified teachers provide personalized coaching to
                                help you achieve your musical goals. With regular recitals and the
                                opportunity to take level exams from international boards, we ensure
                                a well-rounded learning experience.
                            </p>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                                The Importance of Learning Guitar
                            </h2>
                            <img
                                src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_4_17839d7d63.webp"
                                alt="The Importance of Learning Guitar"
                                className="w-full rounded-xl mb-6"
                            />

                            <div className="space-y-4">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">For Kids</h3>
                                    <ul className="list-disc list-inside text-gray-700">
                                        <li>
                                            <strong>Enhances Cognitive Skills:</strong> Learning
                                            guitar improves memory, coordination, and
                                            problem-solving abilities in children.
                                        </li>
                                        <li>
                                            <strong>Boosts Creativity:</strong> Music encourages
                                            self-expression and imaginative thinking.
                                        </li>
                                        <li>
                                            <strong>Builds Confidence:</strong> Mastering an
                                            instrument helps kids develop self-esteem and stage
                                            presence.
                                        </li>
                                    </ul>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">For Adults</h3>
                                    <ul className="list-disc list-inside text-gray-700">
                                        <li>
                                            <strong>Stress Relief:</strong> Playing the guitar is a
                                            relaxing activity that helps reduce stress and improve
                                            mental well-being.
                                        </li>
                                        <li>
                                            <strong>Encourages Lifelong Learning:</strong> It’s
                                            never too late to pick up a new skill. Guitar playing
                                            keeps the mind sharp and engaged.
                                        </li>
                                        <li>
                                            <strong>Fosters Social Connections:</strong> Join jam
                                            sessions, form bands, or simply play for loved
                                            ones—guitar brings people together.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
                                Why Choose SpArts Guitar Academies?
                            </h2>
                            <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-blue-600">
                                “Guitar is the best form of self-expression I know. Everything else,
                                and I’m just sort of tripping around, trying to figure my way
                                through life.” &mdash; Slash
                            </blockquote>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Certified Teachers
                                    </h3>
                                    <p className="text-gray-700">
                                        Our instructors are highly experienced and certified,
                                        bringing expertise and enthusiasm to every session. Their
                                        guidance ensures students excel at every level of their
                                        guitar journey.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        International Level Exams{' '}
                                    </h3>
                                    <p className="text-gray-700">
                                        Prepare for globally recognized certifications, including
                                        Trinity College London and ABRSM exams. Achieving these
                                        milestones validates your skill and opens doors to
                                        international music opportunities.
                                    </p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">
                                        Regular Recitals
                                    </h3>
                                    <p className="text-gray-700">
                                        We believe in learning through performance. Regular recitals
                                        allow students to showcase their progress, build confidence,
                                        and enjoy the thrill of performing for an audience.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <div className="relative mx-auto bg-[#9adaf1] rounded-lg shadow-lg p-6">
                            <div className="text-center">
                                <h2 className="text-3xl font-bold text-blue-600">Did You Know?</h2>
                            </div>

                            <ul className="text-gray-700 mt-4 list-inside space-y-2">
                                <li>
                                    Over 20,000 musicians worldwide achieve Level 8 Guitar
                                    certification from prestigious boards like Trinity College
                                    London and ABRSM annually.
                                </li>
                                <li>
                                    Among these, an estimated 1,000 students are from India,
                                    showcasing the country’s growing passion for guitar excellence.
                                </li>
                            </ul>
                        </div>

                        <section className="text-center py-8 bg-blue-600 text-white rounded-xl shadow-lg mt-6 p-2">
                            <h2 className="text-3xl font-bold mb-4">
                                Start Your Guitar Journey Today!
                            </h2>
                            <p className="mb-6">
                                Whether you aspire to perform on stage or simply play for joy,
                                SpArts Guitar Academy is here to guide you.
                            </p>
                            <button
                                className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-gray-100"
                                onClick={() => navigate(Routes.academies + '?disciplines=guitar')}
                            >
                                Explore
                            </button>
                            <div className="mt-4">
                                <button
                                    className="px-6 py-3 bg-blue-500 text-white font-bold rounded-full shadow-md hover:bg-blue-400"
                                    onClick={() => navigate(Routes.SignUp)}
                                >
                                    Sign Up for a Free Trial Class
                                </button>
                                <button
                                    className="m-4 px-6 py-3 bg-blue-500 text-white font-bold rounded-full shadow-md hover:bg-blue-400"
                                    onClick={() => navigate(Routes.contactUs)}
                                >
                                    Contact Us
                                </button>
                            </div>
                        </section>
                    </div>
                </body>
            </div>
            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default GuitarDisciplinePage;
