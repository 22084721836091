import React from 'react'
import Navbar from '../../../../components/landing-page-items/navbar'
import Footer from '../../../../components/landing-page-items/footer'
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../navigation/routes'
import { Helmet } from 'react-helmet-async'

const CricketDisciplinePage = () => {
  const navigate = useNavigate();

  return (
      <div>
        <Helmet>
            <title>
                Best cricket coaching centre, Cricket Training Academy in bangalore - SpArts
              </title>
            <meta name="description" content="Join SpArts, the best cricket coaching center in Bangalore. Expert cricket training, world-class facilities, and personalized coaching to boost your cricket skills!" />

            <link rel="canonical" href={`https://students.sparts.app/discipline/cricket`} />
        </Helmet>
        <Navbar />
        <div className='md:px-10 px-2'>
            <header className='w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-yellow-900'>
                <video 
                    src='https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Cricket_Classes_Where_Dreams_Take_Guard_ccfcfa0a64.webm' 
                    autoPlay 
                    muted 
                    loop 
                    className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40'
                />
                <div className='text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg'>
                    <h1 className='text-[#F9CA54] underline underline-offset-8'>Cricket</h1>
                    <div className='shadow-black'>SpArts Cricket Classes,</div>
                    <div className='shadow-black'>Where Dreams take guard!</div>
                </div>
            </header>
            <body className="bg-gray-50 text-gray-800 font-sans">
                <div className="max-w-5xl mx-auto p-6">
                    <div className="text-center py-6">
                        <h1 className="text-4xl font-bold text-green-600">SpArts Cricket Academy</h1>
                        <p className="mt-2 text-lg">Your Gateway to Cricketing Excellence.</p>
                    </div>

                    <section className="mb-8">
                        <p className="text-center text-gray-700 text-lg">
                            At SpArts Cricket Academy, we believe cricket is more than a sport – it’s a way of life. Whether you’re a budding player or an enthusiast aiming to refine your skills, our academies provide the perfect platform to achieve your cricketing dreams. From regular tournaments to athlete-focused fitness programs and expert coaching, we have it all.
                        </p>
                    </section>

                    <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                        <h2 className="text-2xl font-semibold text-green-600 mb-4">The Importance of Cricket</h2>
                        <img src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_ad26673183.webp" alt="Benefits of Cricket" className="w-full rounded-xl mb-6" />

                        <div className="space-y-4">
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">For Kids</h3>
                                <ul className="list-disc list-inside text-gray-700">
                                    <li><strong>Encourages teamwork, discipline, and sportsmanship:</strong> Cricket teaches valuable life skills.</li>
                                    <li><strong>Enhances motor skills, hand-eye coordination, and focus:</strong> Build essential physical and mental abilities.</li>
                                    <li><strong>Builds confidence and a sense of achievement:</strong> Celebrate milestones and progress on the field.</li>
                                </ul>
                            </div>
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">For Adults</h3>
                                <ul className="list-disc list-inside text-gray-700">
                                    <li><strong>Provides a great workout:</strong> Improves cardiovascular health and overall fitness.</li>
                                    <li><strong>Offers stress relief:</strong> Enjoy a fun and social activity.</li>
                                    <li><strong>Keeps the competitive spirit alive:</strong> Cricket nurtures lifelong learning and challenges.</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-green-600 mb-4">Why Choose SpArts Cricket Academies?</h2>
                        <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-green-600">
                            “Cricket has taught me more about life than anything else. It has taught me discipline, perseverance, and the importance of teamwork.” &mdash; Mahendra Singh Dhoni
                        </blockquote>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">Regular Tournaments</h3>
                                <p className="text-gray-700">Compete, learn, and grow. At SpArts, our students participate in local, regional, and inter-academy tournaments, ensuring they’re always game-ready and gaining valuable match experience.</p>
                            </div>
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">Athlete Fitness Programs</h3>
                                <p className="text-gray-700">We focus on building strength, agility, and endurance through sport-specific fitness regimens. Our goal is to make players physically and mentally equipped for the challenges on the field.</p>
                            </div>
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">Experienced Coaches</h3>
                                <p className="text-gray-700">Learn from seasoned mentors with proven track records. Our coaches bring years of experience, including playing and coaching in premier tournaments like the Ranji Trophy and domestic leagues.</p>
                            </div>
                        </div>
                    </section>

                    <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                        <h2 className="text-2xl font-semibold text-green-600 mb-4">Cricket and Academics – A Winning Partnership</h2>
                        <p className="text-gray-700">
                            In a groundbreaking move, IIT Madras has introduced supernumerary seats for Sports Excellence Admissions across branches. Cricket is among the 13 sports they consider for these prestigious seats. <a href={`${Routes.blogs}`} target="_blank" className="text-green-600 underline">Learn more about this opportunity in our blogs.</a>
                        </p>
                    </section>

                    <section className="text-center py-8 bg-green-600 text-white rounded-xl shadow-lg">
                        <h2 className="text-3xl font-bold mb-4">Join the SpArts Cricket Family</h2>
                        <p className="mb-6">At SpArts Cricket Academy, we’re not just creating players; we’re building champions on and off the field. Whether you aspire to play for India or enjoy a friendly weekend match, our academy is your gateway to cricketing excellence.</p>
                        <button onClick={() => navigate(Routes.academies + '?discipline=cricket')} className="px-6 py-3 bg-white text-green-600 font-bold rounded-full shadow-md hover:bg-gray-100">Enroll Now</button>
                        <div className="mt-4">
                            <button onClick={() => navigate(Routes.SignUp)} className="px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400">Sign Up for a Free Trial Class</button>
                            <button onClick={() => navigate(Routes.contactUs)} className="ml-4 px-6 py-3 bg-green-500 text-white font-bold rounded-full shadow-md hover:bg-green-400">Contact Us</button>
                        </div>
                    </section>
                </div>
            </body>
        </div>
        <Footer />
        <WhatsAppButton />
    </div>
  )
}

export default CricketDisciplinePage