
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";
import { FacebookIcon, LinkedinIcon } from "react-share";
import { FaYoutube } from "react-icons/fa6";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='p-6 py-4 md:px-32 md:py-20 mt-8 w-full bg-[#020a17] grid grid-cols-3 md:grid-cols-5 gap-y-8 md:gap-0 text-slate-100'>
                <div>
                    <div className='text-sm md:text-lg font-semibold py-1'>INFO</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.contactUs)}>Contact Us</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg'>FAQ</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg'></div>
                </div>
                <div>
                    <div className='text-sm md:text-lg font-semibold py-1'>RESOURCES</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.blogs)}>Blog</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.academies)}>Academy Partners</div>
                </div>
                <div>
                    <div className='text-sm md:text-lg font-semibold py-1'>COMPANY</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.aboutUs)}>About Us</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.privacyPolicy)}>Priacy Policy</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.termsAndConditions)}>Terms & Conditions</div>
                    <div className='py-1 cursor-pointer text-xs md:text-lg' onClick={() => navigate(Routes.cookiePolicy)}>Cookie Policy</div>
                </div>
                <div className='col-span-3 md:col-span-2 flex flex-col gap-4'>
                    <div>
                        <div className='font-semibold text-center md:text-left'>Subscibe to our newsletter</div>
                        <form className='flex items-center gap-2 md:mt-2'>
                            <input 
                                type='email'
                                placeholder='Your email'
                                required
                                className='bg-transparent sm:rounded-md sm:border border-b focus:outline-none py-3 px-4 w-full cursor-text text-center md:text-left'
                            />
                            <button type='submit' className='bg-white text-[#020a17] font-bold py-3 px-4 rounded cursor-pointer sm:block hidden'>SUBSCRIBE</button>
                        </form>
                    </div>
                    <div>
                        <div className='text-lg font-semibold py-1 text-center md:text-left'>FOLLOW US</div>
                        <div className='flex gap-4 justify-center md:justify-start'>
                            <a className="flex items-center" target='_blank' href='https://www.facebook.com/SpArtsTech/'>
                                <img 
                                    src='/assets/images/facebook.webp'
                                    className='h-12 cursor-pointer'
                                    alt='facebook'
                                />
                            </a>
                            <a className="flex items-center" target='_blank' href='https://www.instagram.com/spartstech/'>
                                <img 
                                    src='/assets/images/instagram.webp'
                                    className='h-12 cursor-pointer'
                                    alt='instagram'
                                />
                            </a>
                            <a className="flex items-center" target='_blank' href='https://www.youtube.com/channel/UCHlKcjvUkQkPLycrALD3LmA'>
                                <img 
                                    src='/assets/images/youtube.webp'
                                    className='h-12 cursor-pointer'
                                    alt='youtube'
                                />
                            </a>
                            <a className="flex items-center" target='_blank' href='https://in.linkedin.com/company/sparts-technologies'>
                                <LinkedinIcon 
                                    size={38} 
                                    round={true}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center bg-[#020a17] text-slate-200 py-2'>© 2022 SpArts. All rights reserved.</div>
        </>
    );
}

export default Footer