import style from "./style.module.css";

import SignUpPageViewModel from "../../view-models/signup-page-view-model";
import FullScreenLoader from "../../components/common/full-sreen-loader";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Routes } from '../../navigation/routes';
import PwaInstallPrompt from "../../components/common/pwa-install-prompt";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
interface InputFieldProps {
    label: string;
    type: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isError?: boolean;
    errorText?: string;
    showEye?: 'visible' | 'hidden';
    onEyeClick?: () => void;
    isRequired: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
    label,
    type,
    value,
    onChange,
    isError = false,
    errorText = '',
    showEye,
    onEyeClick,
    isRequired
}) => (
    <div className="w-1/2">
        <p className=" text-sm flex items-center justify-between">
            <div>{label}</div>
            {isError && <div className="text-red-400 text-xs">{errorText}</div>}
        </p>
        <div className="relative">
            <input
                className={`h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b focus:outline-none px-[1vw] mt-2 w-full cursor-text ${isError ? 'ring-2 ring-red-400' : ''}`}
                placeholder=""
                type={type}
                value={value}
                onChange={onChange}
                required={isRequired}
            />
            {showEye && (
                <i
                    className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${showEye === 'visible' ? 'ri-eye-line' : 'ri-eye-off-line'} text-[#3AB7FC] font-normal text-xl cursor-pointer`}
                    onClick={onEyeClick}
                />
            )}
        </div>
    </div>
);

const SignUp = () => {
    const {
        handleLoginClick,
        isPasswordVisible,
        isConfirmPasswordVisible,
        handlePasswordEyeClick,
        handleConfirmPasswordEyeClick,
        registrationFormData,
        handleFormInputChange,
        registerSubmit,
        isLoading,
        isUserNameTaken,
        setIsRegisterSuccess,
        isEmailTaken,
        isPasswordMatching,
        countdown,
        noContactInfo,
        isRegisterSuccess,
        isPhoneNumberTaken,
    } = SignUpPageViewModel();

    const navigate = useNavigate();

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
    };

    const isNative = Capacitor.isNativePlatform();

    useEffect(() => {
        if(isNative) {
            StatusBar.setOverlaysWebView({ overlay: false });
            StatusBar.setBackgroundColor({ color: '#FFFFFF' });
            StatusBar.setStyle({ style: Style.Light });
        }
    },[isNative])

    return (
        <div className="sm:flex w-full">
            <Helmet>
                <title>SpArts Student Portal SignUp | Access Your Sports & Arts Classes</title>
                <meta name='description' content='Log in to the SpArts Student Portal to track attendance, receive assignments, stay updated with notifications, and engage with SpArts AI exercises. Your gateway to personalized growth in sports and arts.' />
                <link rel="canonical" href={`https://students.sparts.app/sign-up`} />
            </Helmet>
            {isLoading && <FullScreenLoader />}
            <div className="sm:block hidden bg-[#3AB7FC] w-[40%] h-svh"></div>
            <div className="bg-white sm:w-[60%] w-full h-svh flex flex-col">
                {!isNative && (
                    <div className="w-full sm:h-[15%] h-[10%] bg-[#F6B40A]">
                        <div className="w-full h-[35%]"></div>
                        <div className="flex justify-center w-full h-[50%]">
                            <img
                                className=" "
                                src="/assets/images/logo.svg"
                                alt="link"
                            />
                        </div>
                    </div>
                )}
                <div className="flex-grow w-full flex flex-col justify-center">
                    <form className="sm:mt-8" action="" onSubmit={registerSubmit}>
                        <div className="sm:p-0 p-6 sm:px-20 pt-10">
                            <h1 className="text-center sm:text-left text-4xl font-normal">Sign Up</h1>
                            <h2 className="sm:hidden text-center text-md font-normal mt-10">Create your account</h2>
                            <div className="sm:mt-10 mt-20"></div>
                            <div className="sm:block hidden">
                                {/* <div >
                                    <p className=" text-sm flex items-center justify-between">
                                        <div>Username</div>
                                        {isUserNameTaken && <div className="text-red-400 text-xs">Username not available!</div>}
                                    </p>
                                    <input 
                                        type="text"
                                        minLength={3}
                                        value={registrationFormData.userName}
                                        onChange={e => {
                                            handleFormInputChange('userName', e.target.value)
                                            if(e.target.value.length < 3){
                                                e.target.setCustomValidity('Username must be atleast 3 characters.');
                                            }else{
                                                e.target.setCustomValidity('');
                                            }
                                        }}
                                        // placeholder="Username"
                                        className={`h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b focus:outline-none px-[1vw] mt-2 w-full cursor-text ${isUserNameTaken ? 'ring-2 ring-red-400' : ''}`}
                                    />
                                </div> */}
                                <div className="flex w-full justify-between gap-4">
                                    <InputField
                                        isRequired={true}
                                        label="First Name"
                                        type="text"
                                        value={registrationFormData.firstName}
                                        onChange={e => handleFormInputChange('firstName', e.target.value)}
                                    />
                                    <InputField
                                        isRequired={false}
                                        label="Last Name"
                                        type="text"
                                        value={registrationFormData.lastName}
                                        onChange={e => handleFormInputChange('lastName', e.target.value)}
                                    />
                                </div>
                                <div className="flex w-full justify-between gap-4">
                                    <div className="w-1/2">
                                        <p className=" text-sm flex items-center justify-between">
                                            <div>Email</div>
                                            {isEmailTaken && <div className="text-red-400 text-xs">This email is already registered!</div>}
                                        </p>
                                        <div className="relative">
                                            <input
                                                className={`h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b focus:outline-none px-[1vw] mt-2 w-full cursor-text ${(isEmailTaken || noContactInfo) ? 'ring-2 ring-red-400' : ''}`}
                                                placeholder=""
                                                type='email'
                                                value={registrationFormData.email}
                                                onChange={e => handleFormInputChange('email', e.target.value)}
                                                required={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <p className=" text-sm flex items-center justify-between">
                                            <div>Phone Number</div>
                                            {isPhoneNumberTaken && <div className="text-red-400 text-xs">This phone number is already registered!</div>}
                                        </p>
                                        <div className="relative">
                                            <input
                                                className={`h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b focus:outline-none px-[1vw] mt-2 w-full cursor-text ${(noContactInfo || isPhoneNumberTaken) ? 'ring-2 ring-red-400' : ''}`}
                                                placeholder=""
                                                type='number'
                                                pattern="\d{10}"
                                                value={registrationFormData.phoneNumber}
                                                onChange={e => {
                                                    if(e.target.value === ''){
                                                        e.target.setCustomValidity('');
                                                    }else if(!(/^\d{10}$/.test(e.target.value))){
                                                        e.target.setCustomValidity('Enter a valid phone number.');
                                                    }else if(isPhoneNumberTaken){
                                                        e.target.setCustomValidity('This number is already registered!')
                                                    }else{
                                                        e.target.setCustomValidity('');
                                                    }
                                                    handleFormInputChange('phoneNumber', e.target.value)
                                                }}
                                                required={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-full justify-between gap-4">
                                    <div className="w-1/2">
                                        <p className=" text-sm flex items-center justify-between">
                                            <div>Password</div>
                                        </p>
                                        <div className="relative">
                                            <input
                                                className={`h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b focus:outline-none px-[1vw] mt-2 w-full cursor-text`}
                                                placeholder=""
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                minLength={6}
                                                value={registrationFormData.password}
                                                onChange={e => {
                                                    handleFormInputChange('password', e.target.value)
                                                    if(e.target.value.length < 6){
                                                        e.target.setCustomValidity('Password must be atleast 6 characters.');
                                                    }else{
                                                        e.target.setCustomValidity('');
                                                    }
                                                }}
                                                required={true}
                                            />
                                            <i
                                                className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${isPasswordVisible ? 'ri-eye-line' : 'ri-eye-off-line'} text-[#3AB7FC] font-normal text-xl cursor-pointer`}
                                                onClick={handlePasswordEyeClick}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <p className=" text-sm flex items-center justify-between">
                                            <div>Confirm Password</div>
                                            {!isPasswordMatching && <div className="text-red-400 text-xs">Passwords do not match!</div>}
                                        </p>
                                        <div className="relative">
                                            <input
                                                className={`h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b focus:outline-none px-[1vw] mt-2 w-full cursor-text ${!isPasswordMatching ? 'ring-2 ring-red-400' : ''}`}
                                                placeholder=""
                                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                                // minLength={6}
                                                onPaste={handlePaste}
                                                value={registrationFormData.confirmPassword}
                                                onChange={e => handleFormInputChange('confirmPassword', e.target.value)}
                                                required={true}
                                            />
                                            <i
                                                className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${isConfirmPasswordVisible ? 'ri-eye-line' : 'ri-eye-off-line'} text-[#3AB7FC] font-normal text-xl cursor-pointer`}
                                                onClick={handleConfirmPasswordEyeClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {noContactInfo &&
                                    <div className="pt-1 w-full hidden sm:block">
                                        <div className="text-red-400 text-sm w-full text-center">Please fill in either email or phone number!</div>
                                    </div>
                                }
                            </div>

                            {/* Mobile View Form */}
                            <div className="sm:hidden">
                                {/* <div>
                                    <input 
                                        type='text'
                                        minLength={3}
                                        required
                                        value={registrationFormData.userName}
                                        onChange={e => {
                                            handleFormInputChange('userName', e.target.value)
                                            if(e.target.value.length < 3){
                                                e.target.setCustomValidity('Username must be atleast 3 characters.');
                                            }else{
                                                e.target.setCustomValidity('');
                                            }
                                        }}
                                        placeholder="Username"
                                        className={`rounded-2xl shadow-sm pl-2 p-1 border-b-2 w-full mt-4 focus:outline-none ${isUserNameTaken ? 'border-red-400' : ''}`}
                                    />
                                </div> */}
                                <input
                                    placeholder="Name"
                                    type="text"
                                    value={registrationFormData.firstName}
                                    onChange={e => handleFormInputChange('firstName', e.target.value)}
                                    required
                                    className="rounded-2xl shadow-sm pl-2 p-1 border-b-2 w-full mt-4 focus:outline-none"
                                />
                                <input
                                    placeholder="Email"
                                    type="email"
                                    // required
                                    value={registrationFormData.email}
                                    onChange={e => handleFormInputChange('email', e.target.value)}
                                    className={` rounded-2xl shadow-sm pl-2 p-1 border-b-2 w-full mt-4 focus:outline-none ${(isEmailTaken || noContactInfo) ? 'border-red-400' : ''}`}
                                />
                                <input
                                    placeholder="Phone number"
                                    type="number"
                                    pattern="\d{10}"
                                    value={registrationFormData.phoneNumber}
                                    onChange={e => {
                                        if(e.target.value === ''){
                                            e.target.setCustomValidity('');
                                        }else if(!(/^\d{10}$/.test(e.target.value))){
                                            e.target.setCustomValidity('Enter a valid phone number.');
                                        }else if(isPhoneNumberTaken){
                                            e.target.setCustomValidity('This number is already registered!')
                                        }else{
                                            e.target.setCustomValidity('');
                                        }
                                        handleFormInputChange('phoneNumber', e.target.value)
                                    }}
                                    className={`rounded-2xl shadow-sm pl-2 p-1 border-b-2 w-full mt-4 focus:outline-none ${(noContactInfo || isPhoneNumberTaken) ? 'border-red-400' : ''}`}
                                    
                                />
                                <div className="rounded-2xl shadow-sm pl-2 p-1 border-b-2 w-full mt-4 flex justify-between">
                                    <input
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        placeholder="Password"
                                        className="flex-grow focus:outline-none"
                                        minLength={6}
                                        required
                                        onChange={e => {
                                            handleFormInputChange('password', e.target.value)
                                            if(e.target.value.length < 6){
                                                e.target.setCustomValidity('Password must be atleast 6 characters.');
                                            }else{
                                                e.target.setCustomValidity('');
                                            }
                                        }}
                                        value={registrationFormData.password}
                                    />
                                    {isPasswordVisible && (
                                        <i
                                            className="ri-eye-line cursor-pointer text-[#3AB7FC] font-normal text-xl mr-4"
                                            onClick={handlePasswordEyeClick}></i>
                                    )}
                                    {!isPasswordVisible && (
                                        <i
                                            className="ri-eye-off-line cursor-pointer text-[#3AB7FC] font-normal text-xl mr-4"
                                            onClick={handlePasswordEyeClick}></i>
                                    )}
                                </div>
                                <div className={`rounded-2xl shadow-sm pl-2 p-1 border-b-2 w-full mt-4 flex justify-between ${!isPasswordMatching ? 'border-red-400' : ''}`}>
                                    <input
                                        type={isConfirmPasswordVisible ? 'text' : 'password'}
                                        placeholder="Confirm your password"
                                        className="flex-grow focus:outline-none"
                                        // minLength={6}
                                        required
                                        onPaste={handlePaste}
                                        value={registrationFormData.confirmPassword}
                                        onChange={e => handleFormInputChange('confirmPassword', e.target.value)}
                                    />
                                    {isConfirmPasswordVisible && (
                                        <i
                                            className="ri-eye-line cursor-pointer text-[#3AB7FC] font-normal text-xl mr-4"
                                            onClick={handleConfirmPasswordEyeClick}></i>
                                    )}
                                    {!isConfirmPasswordVisible && (
                                        <i
                                            className="ri-eye-off-line cursor-pointer text-[#3AB7FC] font-normal text-xl mr-4"
                                            onClick={handleConfirmPasswordEyeClick}></i>
                                    )}
                                </div>
                            </div>

                            <div className="pt-2">
                                {/* {isUserNameTaken && 
                                    <div className="pt-1 w-full sm:hidden">
                                        <div className="text-red-400 text-xs w-full text-center">Username not available!</div>
                                    </div>
                                } */}
                                {isEmailTaken && 
                                    <div className="pt-1 w-full sm:hidden">
                                        <div className="text-red-400 text-xs w-full text-center">This email is already registered!</div>
                                    </div>
                                }
                                {!isPasswordMatching && 
                                    <div className="pt-1 w-full sm:hidden">
                                        <div className="text-red-400 text-xs w-full text-center">Passwords do not match!</div>
                                    </div>
                                }
                                {noContactInfo &&
                                    <div className="pt-1 w-full sm:hidden">
                                        <div className="text-red-400 text-xs w-full text-center">Please fill in either email or phone number!</div>
                                    </div>
                                }
                                {isPhoneNumberTaken &&
                                    <div className="pt-1 w-full sm:hidden">
                                        <div className="text-red-400 text-xs w-full text-center">This phone number is already registered!</div>
                                    </div>
                                }
                            </div>

                            <input
                                type="checkbox"
                                id="rememberMe"
                                className="ml-3 sm:mt-8 mt-12 cursor-pointer"
                            />
                            <label htmlFor="rememberMe" className="ml-3">
                                Remember me
                            </label>
                            <br />
                            <div className="flex justify-between">
                                <div>
                                    <input
                                        type="checkbox"
                                        id="termsAndConditions"
                                        className="mt-3 ml-3 cursor-pointer"
                                    />
                                    <label
                                        htmlFor="termsAndConditions"
                                        className="ml-3">
                                        I agree to all the{' '}
                                        <span className="text-[#3AB7FC] cursor-pointer" onClick={() => navigate(Routes.termsAndConditions)}>
                                            Terms
                                        </span>{' '}
                                        and{' '}
                                        <span className="text-[#3AB7FC] cursor-pointer" onClick={() => navigate(Routes.privacyPolicy)}>
                                            Privacy Policy
                                        </span>
                                    </label>
                                </div>
                                {/* <p className="text-[#3AB7FC] cursor-pointer sm:block hidden">
                                    Forgot password?
                                </p> */}
                            </div>
                        </div>

                        <div className="sm:px-20 px-10 flex justify-center text-center items-center mt-8">
                            <button
                                type="submit"
                                className="bg-[#3AB7FC] p-2 sm:rounded text-white w-full rounded-full sm:font-semibold font-semibold disabled:opacity-20 "
                                disabled={isEmailTaken || isPhoneNumberTaken}
                            >
                                Create account
                            </button>
                            {/* <button className="bg-black p-2 text-white w-[40%] rounded sm:flex hidden justify-center items-center gap-1 ">
                                <img
                                    className="w-[35px]"
                                    src="./assets/icons/google-logo.svg"
                                    alt=""
                                />
                                Sign-in with google
                            </button> */}
                        </div>
                    </form>
                    <div className="text-center sm:mt-8 mt-6">
                        <p>
                            Already have an account?{' '}
                            <span
                                className="text-[#3AB7FC] cursor-pointer"
                                onClick={handleLoginClick}
                            >
                                Login
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            {isRegisterSuccess && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className='fixed h-[100vh] w-[100vw] top-0 left-0 backdrop-blur-md flex items-center justify-center z-[999999999999]'
                >
                    <div className='gap-2 bg-orange-200 p-2 px-4 rounded-lg shadow-2xl h-[250px] w-[90%] md:w-[520px] flex flex-col items-center justify-center'>
                        <img 
                            src='/assets/images/tick-mark.png'
                            className="bg-white rounded-xl"
                        />
                        <p className="text-xl font-semibold">Successfully registered</p>
                        <p>Redirecting to login in {countdown} seconds...</p>
                    </div>
                </motion.div>
            )}
            {/* <PwaInstallPrompt delay={2}/> */}
        </div>
    );
};

export default SignUp;
