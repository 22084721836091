import React from 'react';
import style from './style.module.css';
import { Helmet } from 'react-helmet-async';
import Navbar from '../../components/landing-page-items/navbar';
import Footer from '../../components/landing-page-items/footer';

const TermsOfUse: React.FC = () => {
    return (
        <div>
            <Helmet>
                <title>SpArts Terms and Conditions | Student Portal Usage Guidelines</title>
                <meta name='description' content={`Review the SpArts Terms and Conditions for using the Student Portal. Understand your rights, responsibilities, and guidelines for accessing attendance, assignments, notifications, and SpArts AI features.`} />
                <link rel="canonical" href={`https://students.sparts.app/terms-conditions`} />
            </Helmet>
            <Navbar />
            <div className={style.termsofuse}>
                <ol>
                    <h1 className="text-4xl font-semibold mb-8 mt-20">
                        SpArts - Terms of Use – V02 – Sep 2024
                    </h1>
                    <p>
                        This document is an electronic record in terms of Information Technology
                        Act, 2000 and rules there under as applicable and the amended provisions
                        pertaining to electronic records in various statutes as amended by the
                        Information Technology Act, 2000. This document is published in accordance
                        with the provisions of Rule 3 (1) of the Information Technology
                        (Intermediaries guidelines) Rules, 2011 that require publishing the rules
                        and regulations, privacy policy and Terms of Use for access or usage of
                        www.sparts.app website and SpArts application for desktop, mobile and
                        handheld devices.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Acceptance of Agreement</h2>
                    </li>
                    <p>
                        By using the SpArts platform (including any mobile or web applications, and
                        services), you agree to the terms and conditions outlined in this Agreement
                        with respect to the Site and Services. SpArts reserves the right to amend
                        this Agreement at any time without prior notice. If changes are made, we
                        will notify you via a notice on the Site or other reasonable means. Your
                        continued use of the Site or Services following any amendment will
                        constitute your acceptance of the amended Agreement. The latest version of
                        this Agreement will always be available on our Site, and you should review
                        it regularly.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Content Ownership</h2>
                    </li>
                    <p>
                        All content, design, graphics, source code (including HTML, CSS, JavaScript,
                        etc.), software, and other intellectual property related to the Site and
                        Services (“Content”) are the property of SpArts and its licensors. The
                        Content is protected by copyright, trademark, patent, and other intellectual
                        property laws. Except as explicitly permitted, no content may be copied,
                        redistributed, or used without prior written consent from SpArts. Any
                        trademarks or names mentioned on the Site, including SpArts, are the
                        property of their respective owners. Customer retains full ownership of
                        their data and trademarks stored within the Site or Services and may export
                        or retain this data at their discretion.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Limited Right to Use</h2>
                    </li>
                    <p>
                        Customers are granted a limited, non-exclusive license to view, download,
                        and print Content from the Site solely for their personal or business use.
                        Redistribution or resale of the Content is strictly prohibited. Access to
                        the Site and certain Services may require registration and subscription,
                        governed by a separate service agreement. Unauthorized access to
                        password-protected areas of the Site is not allowed.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Editing, Deleting, and Modification</h2>
                    </li>
                    <p>
                        SpArts reserves the right to edit, add, or delete any documents,
                        information, functionality, or Content on the Site or Services at any time,
                        with the exception of customer data, which will only be altered with prior
                        consent from the customer.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Non-Transferable</h2>
                    </li>
                    <p>
                        Customer’s right to use the Site and Services is non-transferable. Passwords
                        or access rights provided to the customer may not be transferred or shared
                        with others.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Disclaimer and Limitation of Liability</h2>
                    </li>
                    <p>
                        THE INFORMATION AND SERVICES PROVIDED BY SPARTS ARE PROVIDED “AS IS” WITHOUT
                        ANY WARRANTIES, EITHER EXPRESS OR IMPLIED. SPARTS DISCLAIMS ANY LIABILITY
                        FOR ERRORS, BUGS, OR OTHER PROBLEMS THAT MAY ARISE. WE ARE NOT RESPONSIBLE
                        FOR ANY INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF PROFITS OR
                        BUSINESS INTERRUPTION. OUR TOTAL LIABILITY TO YOU IN ANY EVENT IS LIMITED TO
                        THE LESSER OF THE AMOUNT PAID BY YOU IN THE LAST SIX MONTHS OR INR 10,000.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Third-Party Terms and Services</h2>
                    </li>
                    <p>
                        Some functionality on SpArts may be provided by third-party vendors. You may
                        be required to enter into separate agreements with these third parties to
                        use their services. SpArts is not responsible for third-party services,
                        including payment processors, and you use such services at your own risk.
                        Any agreements made with third parties are between you and those providers,
                        and SpArts assumes no responsibility for disputes arising from such
                        agreements.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Confidentiality and Data Security</h2>
                    </li>
                    <p>
                        SpArts values your privacy, and our use of your personal data is governed by
                        our Privacy Policy. You agree not to disclose any confidential information
                        belonging to SpArts, except as required to perform your obligations under
                        this Agreement. You are responsible for ensuring the security of your data
                        and your system, including ensuring that no unauthorized access occurs.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Links to Other Websites</h2>
                    </li>
                    <p>
                        SpArts may contain links to third-party websites. These links are provided
                        as a convenience, and SpArts is not responsible for the content, accuracy,
                        or opinions expressed on such websites. Use of third-party websites is at
                        your own risk, and we encourage you to read their terms and policies before
                        use.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Refund and Cancellation Policy</h2>
                    </li>
                    <p>
                        If you are not satisfied with a product or service, you may request a refund
                        within 30 days of purchase or subscription. Refunds will not cover setup
                        fees, support fees, or other non-subscription-based charges. Beyond the
                        initial 30 days, no refunds will be provided for subscription fees.
                        Cancellation requests must be made by the account owner, either via phone or
                        email.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Indemnification</h2>
                    </li>
                    <p>
                        You agree to indemnify and hold SpArts, its affiliates, partners, and
                        employees harmless from any claims, liabilities, or losses resulting from
                        your violation of these terms, misuse of the Site or Services, or disputes
                        arising from third-party services or agreements.
                    </p>
                    <li className="m-5 list-decimal font-bold">
                        <h2 className="font-bold">Privacy Policy Terms</h2>
                    </li>
                    <ol>
                        <li>
                            <p>
                                <strong>a. Information Collection:</strong> iClassPro collects
                                personal information from customers when they register on the Site,
                                place an order, subscribe to our services, or engage in other
                                activities. The types of information we may collect include names,
                                email addresses, billing information, and any other information
                                necessary to provide our Services.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>b. Use of Information:</strong> We may use the information
                                we collect from customers for the following purposes:
                                <ul className="list-disc ml-8">
                                    <li>
                                        To process transactions and send notifications regarding
                                        their status.
                                    </li>
                                    <li>
                                        To improve customer service by responding to inquiries and
                                        support needs more efficiently.
                                    </li>
                                    <li>
                                        To personalize user experience by tailoring content and
                                        product offerings to individual preferences.
                                    </li>
                                    <li>
                                        To send periodic emails regarding products, services, or
                                        updates, provided the customer has opted in to receive such
                                        communications.
                                    </li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>c. Information Protection:</strong> We implement a variety
                                of security measures to maintain the safety of personal information.
                                Customer data is stored on secure networks, accessible only by a
                                limited number of individuals with special access rights to such
                                systems, who are required to keep the information confidential.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>d. Data Sharing:</strong> We do not sell, trade, or
                                otherwise transfer to outside parties customer personally
                                identifiable information unless we provide users with advance
                                notice. This does not include trusted third parties who assist us in
                                operating our website, conducting our business, or servicing our
                                customers, so long as those parties agree to keep this information
                                confidential.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>e. Cookies and Tracking Technologies:</strong> The Site may
                                use cookies and similar tracking technologies to enhance user
                                experience. Customers can choose to set their web browser to refuse
                                cookies or to alert them when cookies are being sent. If they do so,
                                note that some parts of the Site may not function properly.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>f. Third-Party Services:</strong> The Site may contain links
                                to third-party websites. iClassPro does not control the content or
                                practices of these third-party sites and is not responsible for
                                their privacy policies. We encourage customers to read the privacy
                                policies of any linked sites.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>g. Changes to the Privacy Policy:</strong> iClassPro
                                reserves the right to update or change this Privacy Policy at any
                                time. Customers will be notified of any significant changes through
                                a notice on our Site or via email. Continued use of the Site and
                                Services after such changes constitutes acceptance of the new
                                Privacy Policy.
                            </p>
                        </li>
                        <li>
                            <strong>h. Contact Information:</strong> If customers have any questions
                            regarding this Privacy Policy, they can contact us at{' '}
                            <strong>info@sparts.app</strong>.
                        </li>
                    </ol>
                </ol>
            </div>
            <Footer />
        </div>
    );
};

export default TermsOfUse;
