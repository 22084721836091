import { Helmet } from "react-helmet-async";
import Navbar from "../../components/landing-page-items/navbar";
import AcademyProfilePageViewModel from "../../view-models/academy-profile-page-viewmodel";
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    WhatsappIcon,
    XIcon,
} from "react-share";
import Footer from "../../components/landing-page-items/footer";
import { Carousel } from "react-responsive-carousel";
import { calculateAverageRating, calculatePercentage, convertTo12HourFormat, getDayOfDateString, getFormattedDate } from "../../../utils/helpers/helpers";
import StarRating from "../../components/common/star-rating";
import React from "react";
import StarRatingInput from "../../components/common/star-rating-input";
import FullScreenLoader from "../../components/common/full-sreen-loader";
import { Routes } from "../../navigation/routes";
import { FaYoutube } from "react-icons/fa6";
import InterestForm from "../../components/common/interestForm";
import PopupModal from "../../components/common/popup-modal";
import { FaCheckCircle } from "react-icons/fa";
import WhatsAppButton from "../../components/common/whatsapp-chat-button";

const AcademyPage = () => {

    const { 
        isLoading,
        academyProfile, 
        visibleReviews,
        showMoreReviews,
        showLessReviews,
        userRating,
        handleRatingChange,
        userReview,
        handleReviewChange,
        handleAddReview,
        user,
        classesData,
        demoBookingFormData,
        handleDemoBookingFormInputChange,
        handleDemoBooking,
        demoBookingFormErrors,
        existingDemoBooking,
        navigate,
        demoBookingFormRef,
        handleScrollToDemoForm,
        isDemoFormInView,
        availableDisciplines,
        availableTimeSlots,
        getWeekDaysFromTomorrow,
        isWithinAWeek,
        openPopup,
        setOpenPopup,
        interestFormData,
        setInterestFormData,
        handleSubmitInterests,
        isPostInterestSuccess,
        setIsPostInterestSuccess,
        isDemoFormOpen,
        handleOpenDemoForm,
        handleCloseDemoForm,
    } = AcademyProfilePageViewModel();

    return (
        <div className="relative">
            {isLoading && <FullScreenLoader />}
            <Helmet>
                <title>{academyProfile?.attributes?.publicName}</title>
                <meta name='description' content={academyProfile?.attributes?.metaTags} />
                <link rel="canonical" href={`https://students.sparts.app/academy/${academyProfile?.attributes?.url}`} />
            </Helmet>
            <Navbar />
            {academyProfile?.attributes?.academy?.data?.attributes?.images?.data && (
                <div className="w-full">
                    <Carousel
                        infiniteLoop
                        centerMode
                        centerSlidePercentage={window.innerWidth >= 768 ? 70 : 95}
                        showArrows
                        showStatus
                        showIndicators
                        showThumbs
                        useKeyboardArrows
                        autoPlay
                        stopOnHover
                        swipeable
                        dynamicHeight={false}
                        emulateTouch
                        autoFocus
                        thumbWidth={100}
                        selectedItem={0}
                        interval={5000}
                        transitionTime={500}
                        swipeScrollTolerance={5}
                        ariaLabel="Academy Images"
                    >
                        {academyProfile?.attributes?.academy?.data?.attributes?.images?.data?.map((img, i) => (
                            <div key={i} className="h-full flex items-center"> 
                                <img
                                    src={img?.attributes?.url}
                                    alt={`Academy Image ${i}`}
                                    className="w-full object-contain" 
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 my-4 md:my-0 md:gap-8 p-1 md:px-20 relative">
                <div className="md:col-span-3 p-2">
                    <div className="flex items-center gap-2 justify-between">
                        <div>
                            <h1 className="text-4xl font-semibold text-left">{academyProfile?.attributes?.publicName}</h1>
                            <div className="text-gray-500 text-sm">{academyProfile?.attributes?.academy?.data?.attributes?.address}</div>
                        </div>
                        <div className="h-8 rounded bg-green-400 text-white font-semibold flex items-center justify-center gap-1 w-[70px] max-w-[70px] flex-shrink-0">
                            <div className="mt-[2px]">
                                {calculateAverageRating(academyProfile?.attributes?.reviewRatings?.map(review => review?.rating) ?? [])?.toFixed(1) ?? 0.0}
                            </div>
                            <img src='/assets/images/star-gray.png' className="h-6 w-6"/>
                        </div>
                    </div>
                    <div className="flex gap-2 items-center py-2 flex-wrap">
                        {academyProfile?.attributes?.academy?.data?.attributes?.selectedDisciplines?.data?.map((d, i) => (
                            <div key={i} className="p-1 px-2 text-xs rounded bg-gray-200">{d?.attributes?.name}</div>
                        ))}
                    </div>
                    <div>
                        <div className="text-2xl font-semibold py-2">Amenities</div>
                        <div className="flex items-center gap-x-20 gap-y-4 flex-wrap">
                            {academyProfile?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Certified coach') && (
                                <div className="flex items-center gap-2">
                                    <img src="/assets/images/coach.png" className="h-6 w-6" />
                                    <div>Certified Coach</div>
                                </div>
                            )}
                            {academyProfile?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Parking') && (
                                <div className="flex items-center gap-2">
                                    <img src="/assets/images/parking.png" className="h-6 w-6" />
                                    <div>Parking</div>
                                </div>
                            )}
                            {academyProfile?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Waiting-room') && (
                                <div className="flex items-center gap-2">
                                    <img src="/assets/images/lounge.png" className="h-6 w-6" />
                                    <div>Waiting Room</div>
                                </div>
                            )}
                            {academyProfile?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Restroom') && (
                                <div className="flex items-center gap-2">
                                    <img src="/assets/images/toilet.png" className="h-6 w-6" />
                                    <div>Restroom</div>
                                </div>
                            )}
                            {academyProfile?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('CCTV') && (
                                <div className="flex items-center gap-2">
                                    <img src="/assets/images/cctv.png" className="h-6 w-6" />
                                    <div>CCTV</div>
                                </div>
                            )}
                            {academyProfile?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Drinking water') && (
                                <div className="flex items-center gap-2">
                                    <img src="/assets/images/water.png" className="h-6 w-6" />
                                    <div>Drinking water</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: academyProfile?.attributes?.pageData ?? '' }}
                        className="border border-slate-100 rounded-md mt-4 shadow-sm"
                    />
                    <div className="py-2">
                        <div className="text-2xl font-semibold">Ratings and reviews</div>
                        <div className="p-4 rounded bg-slate-50 flex items-center justify-center">
                            <div className="flex flex-col-reverse md:flex-row rounded-[15px] gap-[40px] h-auto w-full md:w-auto">
                                <div className="flex justify-between flex-col h-full w-full">
                                    <div className="flex justify-center items-center gap-[15px] h-[30px] py-5 px-2 w-full md:w-auto">
                                        <span className="h-full flex items-center font-medium text-[#444444] text-[30px]">5</span>
                                        <div className="flex items-center font-medium text-[#444444] border relative h-[10px] rounded-[100px] w-[60%] md:w-[350px] bg-[#cfd8dc91]">
                                            <span 
                                                className={`bg-[#66bb6a] h-full rounded-[100px] transition-all duration-300 ease-in-out`}
                                                style={{width: `${calculatePercentage(academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 5).length ?? 0, academyProfile?.attributes?.reviewRatings?.length ?? 0)}%`}}
                                            >
                                            </span>
                                        </div>
                                        <span className="h-full flex items-center font-medium text-[#444444]">
                                            {academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 5).length ?? 0}
                                        </span>
                                    </div>
                                    <div className="flex justify-center items-center gap-[15px] h-[30px] py-5 px-2">
                                        <span className="h-full flex items-center font-medium text-[#444444] text-[30px]">4</span>
                                        <div className="flex items-center font-medium text-[#444444] border relative h-[10px] rounded-[100px] w-[60%] md:w-[350px] bg-[#cfd8dc91]">
                                            <span 
                                                className={`bg-[#66bb6a] h-full rounded-[100px] transition-all duration-300 ease-in-out`}
                                                style={{width: `${calculatePercentage(academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 4).length ?? 0, academyProfile?.attributes?.reviewRatings?.length ?? 0)}%`}}
                                            >
                                            </span>
                                        </div>
                                        <span className="h-full flex items-center font-medium text-[#444444]">
                                            {academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 4).length ?? 0}
                                        </span>
                                    </div>
                                    <div className="flex justify-center items-center gap-[15px] h-[30px] py-5 px-2">
                                        <span className="h-full flex items-center font-medium text-[#444444] text-[30px]">3</span>
                                        <div className="flex items-center font-medium text-[#444444] border relative h-[10px] rounded-[100px] w-[60%] md:w-[350px] bg-[#cfd8dc91]">
                                            <span 
                                                className={`bg-[#66bb6a] h-full rounded-[100px] transition-all duration-300 ease-in-out`}
                                                style={{width: `${calculatePercentage(academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 3).length ?? 0, academyProfile?.attributes?.reviewRatings?.length ?? 0)}%`}}
                                            >
                                            </span>
                                        </div>
                                        <span className="h-full flex items-center font-medium text-[#444444]">
                                            {academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 3).length ?? 0}
                                        </span>
                                    </div>
                                    <div className="flex justify-center items-center gap-[15px] h-[30px] py-5 px-2">
                                        <span className="h-full flex items-center font-medium text-[#444444] text-[30px]">2</span>
                                        <div className="flex items-center font-medium text-[#444444] border relative h-[10px] rounded-[100px] w-[60%] md:w-[350px] bg-[#cfd8dc91]">
                                            <span 
                                                className={`bg-[#66bb6a] h-full rounded-[100px] transition-all duration-300 ease-in-out`}
                                                style={{width: `${calculatePercentage(academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 2).length ?? 0, academyProfile?.attributes?.reviewRatings?.length ?? 0)}%`}}
                                            >
                                            </span>
                                        </div>
                                        <span className="h-full flex items-center font-medium text-[#444444]">
                                            {academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 2).length ?? 0}
                                        </span>
                                    </div>
                                    <div className="flex justify-center items-center gap-[15px] h-[30px] py-5 px-2">
                                        <span className="h-full flex items-center font-medium text-[#444444] text-[30px]">1</span>
                                        <div className="flex items-center font-medium text-[#444444] border relative h-[10px] rounded-[100px] w-[60%] md:w-[350px] bg-[#cfd8dc91]">
                                            <span 
                                                className={`bg-[#66bb6a] h-full rounded-[100px] transition-all duration-300 ease-in-out`}
                                                style={{width: `${calculatePercentage(academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 1).length ?? 0, academyProfile?.attributes?.reviewRatings?.length ?? 0)}%`}}
                                            >
                                            </span>
                                        </div>
                                        <span className="h-full flex items-center font-medium text-[#444444]">
                                            {academyProfile?.attributes?.reviewRatings?.filter(review => review?.rating === 1).length ?? 0}
                                        </span>
                                    </div>
                                </div>
                                <div className="h-full w-full md:w-[150px] flex flex-col justify-center items-center gap-[8px]">
                                    <span className="text-[90px] font-medium">{calculateAverageRating(academyProfile?.attributes?.reviewRatings?.map(review => review?.rating) ?? [])?.toFixed(1) ?? 0.0}</span>
                                    <StarRating ratings={calculateAverageRating(academyProfile?.attributes?.reviewRatings?.map(review => review?.rating) ?? [])}/>
                                    <span className="text-[25px]">{academyProfile?.attributes?.reviewRatings?.length ?? 0}</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="p-2 md:p-4 bg-slate-50 rounded flex justify-center items-stretch transition-all duration-300 relative">
                            <div className="p-3 md:p-6 bg-white rounded-lg">
                                <div className="text-xl md:text-3xl text-center">{userRating?.toFixed(1) ?? 0.0}</div>
                                <StarRatingInput 
                                    disabled={academyProfile?.attributes?.reviewRatings?.find(r => r?.givenBy?.data?.id === user?.user?.id) ? true : false}
                                    value={userRating} 
                                    onChange={handleRatingChange}
                                />
                            </div>
                            <div 
                                className={`bg-white rounded p-2 transition-all duration-300 ${userRating ? 'w-[400px] max-w-[400px] opacity-100' : 'max-w-0 opacity-0 overflow-hidden'}`}
                            >
                                <textarea 
                                    className="border-none h-full outline-none p-2 resize-none w-full rounded bg-slate-50 disabled:opacity-80" 
                                    maxLength={300} 
                                    placeholder="Write a review"
                                    value={userReview}
                                    onChange={e => handleReviewChange(e.target.value)}
                                    disabled={academyProfile?.attributes?.reviewRatings?.find(r => r?.givenBy?.data?.id === user?.user?.id) ? true : false}
                                />
                            </div>
                            {!user?.user?.username && (
                                <div className="absolute top-0 right-0 backdrop-blur-md h-full w-full flex items-center justify-center text-xl text-blue-400 font-semibold">
                                    Login to add review
                                </div>
                            )}
                        </div>
                        {academyProfile?.attributes?.reviewRatings?.find(r => r?.givenBy?.data?.id === user?.user?.id) ? (
                            <div 
                                className={`p-x-4 py-1 rounded bg-slate-200 shadow-sm text-gray-500 text-center transition-all duration-300`}
                            >
                                Submitted by you on {getFormattedDate(academyProfile?.attributes?.reviewRatings?.find(r => r?.givenBy?.data?.id === user?.user?.id)?.timestamp ?? '')}
                            </div>
                        ) : (
                            <div 
                                className={`p-x-4 py-1 rounded bg-blue-400 shadow-sm font-semibold cursor-pointer active:scale-95 text-white text-center transition-all duration-300 ${userRating ? 'opacity-100' : 'opacity-0'}`}
                                onClick={userRating ? handleAddReview : () => {}}
                            >
                                Submit
                            </div>
                        )}
                        <div>
                            {academyProfile?.attributes?.reviewRatings.filter(review => review?.review && review?.review?.length > 0)?.slice(0, visibleReviews)?.map((review, index) => (
                                <React.Fragment key={index}>
                                    <hr className="my-2"/>
                                    <div className="flex items-center justify-between p-2">
                                        <div className="flex items-center gap-2">
                                            <img src={`https://ui-avatars.com/api/name=${review?.givenBy?.data?.attributes?.username}&background=random`} className="h-8 w-8 rounded-full"/>
                                            <div className="font-semibold">{review?.givenBy?.data?.attributes?.username ?? 'Unknown'}</div>
                                            <div className="text-slate-500 text-sm">{getFormattedDate(review?.timestamp)}</div>
                                        </div>
                                        <div className="px-2 h-6 rounded bg-green-400 text-white font-semibold flex items-center justify-center gap-1">
                                            <div className="mt-[2px]">
                                                {review?.rating}
                                            </div>
                                            <img src='/assets/images/star-gray.png' className="h-4 w-4"/>
                                        </div>
                                    </div>
                                    {review?.review && review?.review?.length > 0 && (
                                        <div className="p-2 w-full rounded">
                                            <div>{review?.review}</div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        {academyProfile?.attributes?.reviewRatings && academyProfile?.attributes?.reviewRatings?.length > visibleReviews && (
                            <div className="font-semibold cursor-pointer text-xl text-[#60A5FA] p-2 mt-4" onClick={showMoreReviews}>See all reviews</div>
                        )}
                        {academyProfile?.attributes?.reviewRatings && 
                            academyProfile?.attributes?.reviewRatings.filter(review => review?.review && review?.review?.length > 0)?.length > 0 && 
                            academyProfile?.attributes?.reviewRatings.filter(review => review?.review && review?.review?.length > 0)?.length <= visibleReviews &&
                            academyProfile?.attributes?.reviewRatings.filter(review => review?.review && review?.review?.length > 0)?.length > 4 && (
                            <div className="font-semibold cursor-pointer text-xl text-[#60A5FA] p-2 mt-4" onClick={showLessReviews}>Show less reviews</div>
                        )}
                    </div>
                </div>
                
                <div ref={demoBookingFormRef} className="p-2 h-full relative hidden">
                    <hr className="my-4 border-2"/>

                    <div className='flex gap-8 flex-col justify-between'>
                        <div>
                            <p className="px-4 font-semibold text-right mb-2">Share this profile via</p>
                            <div className='flex flex-wrap items-center justify-end gap-4 px-4 py-0'>
                                <EmailShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <EmailIcon size={38} round={true}/>
                                </EmailShareButton>
                                <FacebookShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <FacebookIcon size={38} round={true}/>
                                </FacebookShareButton>
                                {/* <InstapaperShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <InstapaperIcon size={38} round={true}/>
                                </InstapaperShareButton> */}
                                {/* <LinkedinShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <LinkedinIcon size={38} round={true}/>
                                </LinkedinShareButton> */}
                                <PinterestShareButton url={window.location.href} openShareDialogOnClick={true} media={academyProfile?.attributes?.academy?.data?.attributes?.images?.data ? academyProfile?.attributes?.academy?.data?.attributes?.images?.data[0]?.attributes?.url : ''}>
                                    <PinterestIcon size={38} round={true}/>
                                </PinterestShareButton>
                                <RedditShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <RedditIcon size={38} round={true}/>
                                </RedditShareButton>
                                <TelegramShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <TelegramIcon size={38} round={true}/>
                                </TelegramShareButton>
                                <TwitterShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <XIcon size={38} round={true}/>
                                </TwitterShareButton>
                                <WhatsappShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <WhatsappIcon size={38} round={true}/>
                                </WhatsappShareButton>
                                {/* <TumblrShareButton url={window.location.href} openShareDialogOnClick={true}>
                                    <TumblrIcon size={38} round={true}/>
                                </TumblrShareButton> */}
                            </div>
                        </div>
                        <div>
                            <p className="px-4 font-semibold text-right mb-2">Follow us on</p>
                            <div className='flex flex-wrap justify-end items-center gap-4 px-4'>
                                <a target='_blank' href='https://www.facebook.com/SpArtsTech/'><FacebookIcon size={38} round={true} /></a>
                                <a target='_blank' href='https://in.linkedin.com/company/sparts-technologies'><LinkedinIcon size={38} round={true}/></a>
                                <a target='_blank' href='https://www.instagram.com/spartstech/'><img src="/assets/images/Instagram_icon.png" className='w-9' /></a>
                                <a target='_blank' href='https://www.youtube.com/channel/UCHlKcjvUkQkPLycrALD3LmA'><FaYoutube size={38} color='red'/></a>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4 border-2"/>

                    {existingDemoBooking.length === 0 ? (
                        <div className="rounded-lg w-full flex flex-col items-center justify-center bg-[#F6B40A] sticky top-[80px] p-2 py-4 overflow-hidden shadow-lg shadow-[#F6B40A]">
                            {!user?.user?.username && (
                                <div className="absolute top-0 right-0 backdrop-blur-lg h-full w-full flex items-center justify-center text-xl text-blue-400 font-semibold">
                                    <div className="font-semibold bg-blue-400 text-white p-2 rounded cursor-pointer" onClick={() => navigate(Routes.Login)}>Login to Book a demo</div>
                                </div>
                            )}
                            <div className="flex-grow w-full flex flex-col items-center gap-2">
                                <select
                                    className="p-2 rounded-lg w-full outline-none bg-white"
                                    value={demoBookingFormData.disciplineId}
                                    onChange={(e) => handleDemoBookingFormInputChange('disciplineId', Number(e.target.value))}
                                    disabled={availableDisciplines?.length === 1}
                                >
                                    <option value={0}>Choose a discipline</option>
                                    {availableDisciplines?.map((d, i) => <option key={i} value={d?.id}>{d?.attributes?.name}</option>)}
                                </select>
                                <div className="w-full bg-slate-200 rounded-md  p-2">
                                    <div>
                                        <input 
                                            type="date"
                                            className="p-2 rounded-lg w-full outline-none"
                                            value={demoBookingFormData.date}
                                            min={new Date().toISOString().split('T')[0]}
                                            onChange={(e) => handleDemoBookingFormInputChange('date', e.target.value)}
                                        />
                                        {(isWithinAWeek(new Date(demoBookingFormData.date)) || demoBookingFormData.date === '' )&& (
                                            <>
                                                <div className="flex items-center justify-center my-4">
                                                    <div className="border-t border-gray-300 flex-grow mr-2"></div>
                                                    <span className="text-gray-500">OR</span>
                                                    <div className="border-t border-gray-300 flex-grow ml-2"></div>
                                                </div>
                                                <div>
                                                    <div className="text-gray-500 text-center font-semibold">Upcoming</div>
                                                    <div className="flex items-center justify-center flex-wrap gap-1 mt-2">
                                                        {getWeekDaysFromTomorrow().map(({ dayName, dateString }) => {
                                                            if(availableTimeSlots?.filter(t => t?.timeSlot?.day === dayName && t?.discipline?.data?.id === demoBookingFormData.disciplineId)?.length === 0) return null
                                                            return (
                                                                <div
                                                                    className={`cursor-pointer py-1 px-2 bg-slate-300 rounded border-2 ${getDayOfDateString(demoBookingFormData.date ?? '') === dayName ? ' border-gray-700' : 'border-slate-300'}`}
                                                                    onClick={() => handleDemoBookingFormInputChange('date', dateString)}
                                                                    key={dayName}
                                                                >
                                                                    {dayName}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="p-2 flex flex-col items-center gap-2 rounded bg-slate-200 w-full h-[180px] overflow-y-auto thin-scrollbar">
                                    {demoBookingFormData.disciplineId === 0 ? 
                                        <div className="flex items-center justify-center h-full w-full">Please select a discipline</div> : 
                                        availableTimeSlots?.filter(t => t?.discipline?.data?.id === demoBookingFormData.disciplineId && t?.timeSlot?.day === getDayOfDateString(demoBookingFormData.date))?.map((t, i) => (
                                            <div 
                                                key={i} 
                                                className={`
                                                    p-2 w-full flex items-center justify-between rounded px-4 transition-all border border-slate-400 cursor-pointer 
                                                    ${demoBookingFormData.timing === `${convertTo12HourFormat(t?.timeSlot?.start_time ?? '')} - ${convertTo12HourFormat(t?.timeSlot?.end_time ?? '')}` ? 'bg-green-300' : 'hover:bg-slate-100'}
                                                `}
                                                onClick={() => {
                                                    handleDemoBookingFormInputChange('classId', t?.classId ?? 0)
                                                    handleDemoBookingFormInputChange('timing', `${convertTo12HourFormat(t?.timeSlot?.start_time ?? '')} - ${convertTo12HourFormat(t?.timeSlot?.end_time ?? '')}`)
                                                }}
                                            >
                                                <div className="text-center w-full cursor-pointer">{convertTo12HourFormat(t?.timeSlot?.start_time ?? '')} - {convertTo12HourFormat(t?.timeSlot?.end_time ?? '')}</div>
                                            </div>
                                    ))}
                                    {demoBookingFormData.disciplineId !== 0 && availableTimeSlots?.filter(t => t?.discipline?.data?.id === demoBookingFormData.disciplineId && t?.timeSlot?.day === getDayOfDateString(demoBookingFormData.date))?.length === 0 && (
                                        <div className="flex items-center justify-center h-full w-full">No classes available for this day.</div>
                                    )}
                                </div>
                            </div>
                            {demoBookingFormErrors !== '' &&
                                <div className="w-full text-sm text-red-500 py-2 font-semibold flex items-center justify-center gap-2">
                                    <div className="italic text-red-500 rounded-full border border-red-500 h-4 w-4 flex items-center justify-center text-sm">i</div>
                                    {demoBookingFormErrors}
                                </div>
                            }
                            <div 
                                className="cursor-pointer w-full bg-[#60A5FA] py-2 text-center text-white rounded-lg font-semibold mt-4"
                                onClick={handleDemoBooking}
                            >
                                Book Demo
                            </div>
                        </div>
                    ) : (
                        <div className="rounded-lg w-full flex flex-col gap-2 items-center justify-center bg-[#F6B40A] sticky top-[80px] p-4">
                            <div className="text-white font-semibold w-full flex items-center justify-between">
                                <div className="text-slate-600">Class: </div> 
                                <div>{existingDemoBooking[0]?.attributes?.classesInterested?.data[0]?.attributes?.class_name}</div>
                            </div>
                            <div className="text-white font-semibold w-full flex items-center justify-between">
                                <div className="text-slate-600">Date: </div> 
                                <div>{getFormattedDate(existingDemoBooking[0]?.attributes?.demoDateAndTime ?? '')}</div>
                            </div>
                            <div className="text-white font-semibold w-full flex items-center justify-between">
                                <div className="text-slate-600">Time Slot: </div> 
                                <div>{existingDemoBooking[0]?.attributes?.demoTimeSlot}</div>
                            </div>
                            {/* <div className="text-white font-semibold w-full flex items-center justify-between">
                                <div className="text-slate-600">For: </div> 
                                <div>{existingDemoBooking[0]?.attributes?.firstName}</div>
                            </div>
                            <div className="text-white font-semibold w-full flex items-center justify-between">
                                <div className="text-slate-600">Contact provided: </div> 
                                <div>{existingDemoBooking[0]?.attributes?.contactNo}</div>
                            </div> */}
                            <div className="py-2 bg-blue-400 rounded-lg w-full text-center text-white font-semibold mt-2">
                                Demo Booked on {getFormattedDate(existingDemoBooking[0]?.attributes?.createdAt)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <hr className='border-[1px] border-[#c1c1c167]' />

            {/* Likes and Dislikes */}
            {/* <div>
                <div className="flex space-x-4 justify-end p-4">
                    <button
                        onClick={handleLike}
                        className={`btn bg-slate-100 hover:ring-2 transition-all ring-green-600 focus:outline-none p-2 rounded-md`}
                    >
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.9 4.5C15.9 3 14.418 2 13.26 2c-.806 0-.869.612-.993 1.82-.055.53-.121 1.174-.267 1.93-.386 2.002-1.72 4.56-2.996 5.325V17C9 19.25 9.75 20 13 20h3.773c2.176 0 2.703-1.433 2.899-1.964l.013-.036c.114-.306.358-.547.638-.82.31-.306.664-.653.927-1.18.311-.623.27-1.177.233-1.67-.023-.299-.044-.575.017-.83.064-.27.146-.475.225-.671.143-.356.275-.686.275-1.329 0-1.5-.748-2.498-2.315-2.498H15.5S15.9 6 15.9 4.5zM5.5 10A1.5 1.5 0 0 0 4 11.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 5.5 10z" fill={isLiked ? 'green' : 'gray'}/>
                        </svg>
                    </button>

                    <button
                        onClick={handleDislike}
                        className={`btn bg-slate-100 hover:ring-2 transition-all ring-red-600 focus:outline-none p-2 rounded-md`}
                    >
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.1 20.5c0 1.5 1.482 2.5 2.64 2.5.806 0 .869-.613.993-1.82.055-.53.121-1.174.267-1.93.386-2.002 1.72-4.56 2.996-5.325V8C15 5.75 14.25 5 11 5H7.227C5.051 5 4.524 6.432 4.328 6.964A15.85 15.85 0 0 1 4.315 7c-.114.306-.358.546-.638.82-.31.306-.664.653-.927 1.18-.311.623-.27 1.177-.233 1.67.023.299.044.575-.017.83-.064.27-.146.475-.225.671-.143.356-.275.686-.275 1.329 0 1.5.748 2.498 2.315 2.498H8.5S8.1 19 8.1 20.5zM18.5 15a1.5 1.5 0 0 0 1.5-1.5v-7a1.5 1.5 0 0 0-3 0v7a1.5 1.5 0 0 0 1.5 1.5z" fill={isDisliked ? 'red' : 'gray'}/>
                        </svg>
                    </button>
                </div>
            </div> */}

            <Footer />
            <div 
                className={`fixed bottom-0 left-0 right-0 p-1 transition-all md:mx-[20%] md:my-2 duration-300  ${
                    isDemoFormInView ? 'translate-y-full' : 'translate-y-0'
                }`}
            >
                <div 
                    className="py-2 bg-blue-400 rounded-lg text-center cursor-pointer shadow-xl shadow-gray-500 text-white" 
                    // onClick={handleScrollToDemoForm}
                    onClick={handleOpenDemoForm}
                >
                    <div className="text-lg font-semibold">Book Your Demo</div>
                    <div className="text-sm text-gray-600">Choose a time that works best for you!</div>
                </div>
            </div>
            {openPopup &&
                <InterestForm
                    handleSubmitInterests={handleSubmitInterests}
                    interestFormData={interestFormData}
                    setInterestFormData={setInterestFormData}
                    setOpenPopup ={setOpenPopup}
                />}
            {isPostInterestSuccess && 
                <PopupModal>
                    <div className="flex flex-col w-full p-6 bg-[#f5c750] rounded-md shadow-md">
                        <FaCheckCircle style={{ color: '#3b82f6', fontSize: '3rem', margin: 'auto' }} />
                        <h2 className="text-lg font-semibold mb-4 mt-0 text-center">Thank you for sharing</h2>
                        <p className='text-center'>Your interest is recorded.</p>
                        <p className='text-center'>We will soon get back to you.</p>
                        <button
                                type="button"
                                onClick={() => setIsPostInterestSuccess(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                            >
                                Go back
                        </button>
                    </div>
                </PopupModal>
            }
            {isDemoFormOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-[9999] top-0 left-0 right-0 bottom-0 backdrop-blur-lg">
                    <div onClick={handleCloseDemoForm} className="fixed inset-0 bg-black opacity-80"></div>
                    <div ref={demoBookingFormRef} className="p-2 md:w-[40%] w-full flex flex-col items-center justify-center">
                        {existingDemoBooking.length === 0 ? (
                            <div className="rounded-lg w-full flex flex-col items-center justify-center bg-[#F6B40A] sticky top-[80px] p-2 py-4 overflow-hidden shadow-lg shadow-[#F6B40A]">
                                {!user?.user?.username && (
                                    <div className="absolute top-0 right-0 backdrop-blur-lg h-full w-full flex items-center justify-center text-xl text-blue-400 font-semibold">
                                        <div className="font-semibold bg-blue-400 text-white p-2 rounded cursor-pointer" onClick={() => navigate(Routes.Login)}>Login to Book a demo</div>
                                    </div>
                                )}
                                <div className="flex-grow w-full flex flex-col items-center gap-2">
                                    <select
                                        className="p-2 rounded-lg w-full outline-none bg-white"
                                        value={demoBookingFormData.disciplineId}
                                        onChange={(e) => handleDemoBookingFormInputChange('disciplineId', Number(e.target.value))}
                                        disabled={availableDisciplines?.length === 1}
                                    >
                                        <option value={0}>Choose a discipline</option>
                                        {availableDisciplines?.map((d, i) => <option key={i} value={d?.id}>{d?.attributes?.name}</option>)}
                                    </select>
                                    <div className="w-full bg-slate-200 rounded-md  p-2">
                                        <div>
                                            <input 
                                                type="date"
                                                className="p-2 rounded-lg w-full outline-none"
                                                value={demoBookingFormData.date}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={(e) => handleDemoBookingFormInputChange('date', e.target.value)}
                                            />
                                            {(isWithinAWeek(new Date(demoBookingFormData.date)) || demoBookingFormData.date === '' )&& (
                                                <>
                                                    <div className="flex items-center justify-center my-4">
                                                        <div className="border-t border-gray-300 flex-grow mr-2"></div>
                                                        <span className="text-gray-500">OR</span>
                                                        <div className="border-t border-gray-300 flex-grow ml-2"></div>
                                                    </div>
                                                    <div>
                                                        <div className="text-gray-500 text-center font-semibold">Upcoming</div>
                                                        <div className="flex items-center justify-center flex-wrap gap-1 mt-2">
                                                            {getWeekDaysFromTomorrow().map(({ dayName, dateString }) => {
                                                                if(availableTimeSlots?.filter(t => t?.timeSlot?.day === dayName && t?.discipline?.data?.id === demoBookingFormData.disciplineId)?.length === 0) return null
                                                                return (
                                                                    <div
                                                                        className={`cursor-pointer py-1 px-2 bg-slate-300 rounded border-2 ${getDayOfDateString(demoBookingFormData.date ?? '') === dayName ? ' border-gray-700' : 'border-slate-300'}`}
                                                                        onClick={() => handleDemoBookingFormInputChange('date', dateString)}
                                                                        key={dayName}
                                                                    >
                                                                        {dayName}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-2 flex flex-col items-center gap-2 rounded bg-slate-200 w-full h-[180px] overflow-y-auto thin-scrollbar">
                                        {demoBookingFormData.disciplineId === 0 ? 
                                            <div className="flex items-center justify-center h-full w-full">Please select a discipline</div> : 
                                            availableTimeSlots?.filter(t => t?.discipline?.data?.id === demoBookingFormData.disciplineId && t?.timeSlot?.day === getDayOfDateString(demoBookingFormData.date))?.map((t, i) => (
                                                <div 
                                                    key={i} 
                                                    className={`
                                                        p-2 w-full flex items-center justify-between rounded px-4 transition-all border border-slate-400 cursor-pointer 
                                                        ${demoBookingFormData.timing === `${convertTo12HourFormat(t?.timeSlot?.start_time ?? '')} - ${convertTo12HourFormat(t?.timeSlot?.end_time ?? '')}` ? 'bg-green-300' : 'hover:bg-slate-100'}
                                                    `}
                                                    onClick={() => {
                                                        handleDemoBookingFormInputChange('classId', t?.classId ?? 0)
                                                        handleDemoBookingFormInputChange('timing', `${convertTo12HourFormat(t?.timeSlot?.start_time ?? '')} - ${convertTo12HourFormat(t?.timeSlot?.end_time ?? '')}`)
                                                    }}
                                                >
                                                    <div className="text-center w-full cursor-pointer">{convertTo12HourFormat(t?.timeSlot?.start_time ?? '')} - {convertTo12HourFormat(t?.timeSlot?.end_time ?? '')}</div>
                                                </div>
                                        ))}
                                        {demoBookingFormData.disciplineId !== 0 && availableTimeSlots?.filter(t => t?.discipline?.data?.id === demoBookingFormData.disciplineId && t?.timeSlot?.day === getDayOfDateString(demoBookingFormData.date))?.length === 0 && (
                                            <div className="flex items-center justify-center h-full w-full">No classes available for this day.</div>
                                        )}
                                    </div>
                                </div>
                                {demoBookingFormErrors !== '' &&
                                    <div className="w-full text-sm text-red-500 py-2 font-semibold flex items-center justify-center gap-2">
                                        <div className="italic text-red-500 rounded-full border border-red-500 h-4 w-4 flex items-center justify-center text-sm">i</div>
                                        {demoBookingFormErrors}
                                    </div>
                                }
                                <div 
                                    className="cursor-pointer w-full bg-[#60A5FA] py-2 text-center text-white rounded-lg font-semibold mt-4"
                                    onClick={handleDemoBooking}
                                >
                                    Book Demo
                                </div>
                            </div>
                        ) : (
                            <div className="rounded-lg w-full flex flex-col gap-2 items-center justify-center bg-[#F6B40A] sticky top-[80px] p-4">
                                <div className="text-white font-semibold w-full flex items-center justify-between">
                                    <div className="text-slate-600">Class: </div> 
                                    <div>{existingDemoBooking[0]?.attributes?.classesInterested?.data[0]?.attributes?.class_name}</div>
                                </div>
                                <div className="text-white font-semibold w-full flex items-center justify-between">
                                    <div className="text-slate-600">Date: </div> 
                                    <div>{getFormattedDate(existingDemoBooking[0]?.attributes?.demoDateAndTime ?? '')}</div>
                                </div>
                                <div className="text-white font-semibold w-full flex items-center justify-between">
                                    <div className="text-slate-600">Time Slot: </div> 
                                    <div>{existingDemoBooking[0]?.attributes?.demoTimeSlot}</div>
                                </div>
                                <div className="py-2 bg-blue-400 rounded-lg w-full text-center text-white font-semibold mt-2">
                                    Demo Booked on {getFormattedDate(existingDemoBooking[0]?.attributes?.createdAt)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <WhatsAppButton />
        </div>
    )
}

export default AcademyPage;