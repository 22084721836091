import React from 'react'
import Navbar from '../../../../components/landing-page-items/navbar'
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button'
import Footer from '../../../../components/landing-page-items/footer'
import { Routes } from '../../../../navigation/routes'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const DanceDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                    Best dance academy in bangalore, Online dance classes in bangalore - SpArts
                </title>
                <meta name="description" content="SpArts: The best dance academy in Bangalore. Explore online and in-person dance classes with expert trainers. Perfect for all styles and skill levels. Join now!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/dance`} />
            </Helmet>
            <Navbar />               
            <div className='md:px-10 px-2'>
                <header className='w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-orange-900'>
                    <video 
                        src='https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Dance_Classes_313085360e.webm' 
                        autoPlay 
                        muted 
                        loop 
                        className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40'
                    />
                    <div className='text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg'>
                        <h1 className='text-[#F9CA54] underline underline-offset-8'>Dance</h1>
                        <div className='shadow-black'>SpArts Dance Classes,</div>
                        <div className='shadow-black'>To Express, Inspire and Excel!</div>
                    </div>
                </header>
                <body className="bg-gray-50 text-gray-800 font-sans">
                <div className="max-w-5xl mx-auto p-6">
                    <div className="text-center py-6">
                        <h1 className="text-4xl font-bold text-pink-600">SpArts Dance Classes</h1>
                        <p className="mt-2 text-lg">Celebrating the art of movement and expression.</p>
                    </div>

                    <section className="mb-8">
                        <p className="text-center text-gray-700 text-lg">
                            At SpArts Dance Academies, we celebrate the art of movement and expression. Whether you are a passionate beginner or an aspiring professional, our academies provide expert training in a wide range of dance forms. From the grace of Indian ClassNameical styles like Kathak and Bharatnatyam to the energy of Western dances like Ballet, Latin, and Hip Hop—we offer something for everyone.
                        </p>
                    </section>

                    <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                        <h2 className="text-2xl font-semibold text-pink-600 mb-4">The Importance of Learning Dance</h2>
                        <img src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_2_03c5cb1ac2.webp" alt="Benefits of Dance" className="w-full rounded-xl mb-6"/>

                        <div className="space-y-4">
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">For Kids</h3>
                                <ul className="list-disc list-inside text-gray-700">
                                    <li><strong>Boosts Physical Fitness:</strong> Dance improves flexibility, strength, coordination, and endurance, making it a fun way to stay active.</li>
                                    <li><strong>Encourages Creativity:</strong> Through dance, kids learn to express themselves artistically and develop their imagination.</li>
                                    <li><strong>Builds Social Skills:</strong> Collaborative dance routines teach teamwork, communication, and respect for others.</li>
                                    <li><strong>Improves Focus and Discipline:</strong> Regular practice helps kids develop a strong work ethic and the ability to concentrate.</li>
                                </ul>
                            </div>
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">For Adults</h3>
                                <ul className="list-disc list-inside text-gray-700">
                                    <li><strong>Stress Relief:</strong> Dance is a fantastic way to relax, unwind, and release stress.</li>
                                    <li><strong>Promotes Healthy Living:</strong> Regular dancing supports cardiovascular health and overall physical fitness.</li>
                                    <li><strong>Encourages Lifelong Learning:</strong> Whether you’re rediscovering a childhood passion or trying something new, dance keeps the mind and body agile.</li>
                                    <li><strong>Fosters a Sense of Community:</strong> Dance classes provide a space to connect with like-minded individuals and build lasting friendships.</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-pink-600 mb-4">Why Choose SpArts Dance Academies?</h2>
                        <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-pink-600">
                            “Dance is nature. Listen to your heart. It dances with its own rhythm.” &mdash; Pandit Birju Maharaj
                        </blockquote>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">Expert Training Across Dance Styles</h3>
                                <p className="text-gray-700">Learn from the best in the industry! Our experienced instructors specialize in Indian Classical dance forms and popular Western styles, ensuring a well-rounded dance education.</p>
                            </div>
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">Performance Opportunities</h3>
                                <p className="text-gray-700">Dance is an art meant to be shared. At SpArts, we conduct regular student performances, giving dancers the chance to showcase their skills, build confidence, and experience the joy of performing.</p>
                            </div>
                            <div className="bg-white rounded-xl shadow-md p-4">
                                <h3 className="text-lg font-bold text-gray-800">Pathways to Excellence</h3>
                                <p className="text-gray-700">SpArts supports your cultural aspirations. With IITM now offering seats in bachelor programs for students who excel in cultural activities and clear JEE, your dance journey can open doors to prestigious academic opportunities. Learn more about this in our blogs.</p>
                            </div>
                        </div>
                    </section>

                    <section className="text-center py-8 bg-pink-600 text-white rounded-xl shadow-lg">
                        <h2 className="text-3xl font-bold mb-4">Start Your Dance Journey Today!</h2>
                        <p className="mb-6">Whether you're a child, teenager, or adult, SpArts Dance Academy has a program for you. Join us to explore the world of dance and let your passion shine.</p>
                        <button onClick={() => navigate(Routes.academies + '?discipline=cricket')} className="px-6 py-3 bg-white text-pink-600 font-bold rounded-full shadow-md hover:bg-gray-100">Enroll Now</button>
                        <div className="mt-4">
                            <button onClick={() => navigate(Routes.SignUp)} className="px-6 py-3 bg-pink-500 text-white font-bold rounded-full shadow-md hover:bg-pink-400">Sign Up for a Free Trial Class</button>
                            <button onClick={() => navigate(Routes.contactUs)} className="ml-4 px-6 py-3 bg-pink-500 text-white font-bold rounded-full shadow-md hover:bg-pink-400">Contact Us</button>
                        </div>
                    </section>
                </div>
            </body>
            </div>
            <Footer />
            <WhatsAppButton />
        </div>
    )
}

export default DanceDisciplinePage