import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../../navigation/routes';

declare global {
    interface Window {
        _waEmbed?: (settings: object) => void;
    }
}

const WhatsAppButton = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === Routes.DefaultPage || 
            location.pathname.includes(Routes.academy) || 
            location.pathname.includes(Routes.academies) || 
            location.pathname.includes(Routes.blogs) ||
            location.pathname.includes(Routes.blog) ||
            location.pathname.includes(Routes.scholarship) ||
            location.pathname.includes(Routes.aboutUs) || 
            location.pathname.includes('/discipline')
        ) {
            const script = document.createElement('script');
            script.src = 'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
            script.async = true;

            document.body.appendChild(script);

            const waSettings = {
                btnColor: '#25D366',
                ctaText: '',
                cornerRadius: 40,
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                btnPosition: 'right',
                whatsAppNumber: '919085008017',
                welcomeMessage: 'Hi, I had a query regarding ',
                zIndex: 999999,
                btnColorScheme: 'light',
            };

            window.onload = () => {
                if (window._waEmbed) {
                    window._waEmbed(waSettings);
                }
            };

            // Cleanup script when component unmounts
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [location.pathname]);

    return null;
};

export default WhatsAppButton;
