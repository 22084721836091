import React from 'react'
import Navbar from '../../../../components/landing-page-items/navbar'
import Footer from '../../../../components/landing-page-items/footer'
import WhatsAppButton from '../../../../components/common/whatsapp-chat-button'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../navigation/routes'
import { Helmet } from 'react-helmet-async'

const PianoDisciplinePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Helmet>
                <title>
                    Best piano training in bangalore, Online piano training in bangalore - SpArts
                </title>
                <meta name="description" content="SpArts offers the best piano training in Bangalore. Join online or in-person classes with expert instructors and personalized lessons for all skill levels!"/>
            <link rel="canonical" href={`https://students.sparts.app/discipline/piano`} />
            </Helmet>
            <Navbar />          
            <div className='md:px-10 px-2'>
                <div className='w-full rounded-3xl overflow-hidden relative md:min-h-[680px] flex items-center justify-center bg-blue-900'>
                    <video 
                        src='https://sparts-bucket-qa.s3.amazonaws.com/private/Sp_Arts_Piano_Classes_Turning_Passion_into_Performance_f9f926a8a3.webm' 
                        autoPlay 
                        muted 
                        loop 
                        className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-40'
                    />
                    <div className='text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg'>
                        <h1 className='text-[#F9CA54] underline underline-offset-8'>Piano</h1>
                        <div className='shadow-black'>SpArts Piano Academy,</div>
                        <div className='shadow-black'>Play beautifully, one key at a time!</div>
                    </div>
                </div>
                <body className="bg-gray-50 text-gray-800 font-sans">
                    <div className="max-w-5xl mx-auto p-6">
                        <div className="text-center py-6">
                            <h1 className="text-4xl font-bold text-blue-600">Where Music Meets Mastery</h1>
                            <p className="mt-2 text-lg">Unleash Your Inner Virtuoso with SpArts Piano Academies</p>
                        </div>

                        <section className="mb-8">
                            <p className="text-center text-gray-700 text-lg">
                                At SpArts Piano Academies, we bring you a structured, inspiring, and result-driven approach to mastering the piano. With certified teachers, regular recitals, and level exams from renowned international boards, we help students of all ages embark on their musical journey with confidence.
                            </p>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">Benefits of Learning Piano</h2>
                            <img src="https://sparts-prod.s3.amazonaws.com/private/Benefits_of_Learning_Piano_55bf5c0933.png" alt="Benefits of Learning Piano" className="w-full rounded-xl mb-6" />
                            
                            <div className="space-y-4">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">✨ Improves Cognitive Development</h3>
                                    <p className="text-gray-700">Enhances memory, problem-solving skills, and hand-eye coordination, especially in children.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">✨ Boosts Creativity</h3>
                                    <p className="text-gray-700">Playing piano nurtures artistic expression and creativity, essential for personal growth.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">✨ Reduces Stress & Anxiety</h3>
                                    <p className="text-gray-700">Music is therapy! Playing the piano promotes relaxation and mindfulness for all ages.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">✨ Encourages Discipline & Time Management</h3>
                                    <p className="text-gray-700">Mastering piano requires consistent practice, teaching students valuable life skills.</p>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">SpArts Piano Programs</h2>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">Beginner Program</h3>
                                    <p className="text-gray-700">Focus on fundamentals – hand positioning, rhythm, and basic melodies. Perfect for new learners.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">Intermediate Program</h3>
                                    <p className="text-gray-700">Build on skills with more complex pieces, sight-reading, and music theory.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">Advanced Program</h3>
                                    <p className="text-gray-700">Prepare for Level 8 certifications, perform challenging compositions, and polish your artistry with expert guidance.</p>
                                </div>
                            </div>
                        </section>

                        <section className="mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">Why Choose SpArts Piano Academy?</h2>
                            <blockquote className="italic text-gray-600 bg-gray-100 rounded-xl p-4 border-l-4 border-blue-600">
                                “The piano is the easiest instrument to play in the beginning, and the hardest to master in the end.” &mdash; Vladimir Horowitz
                            </blockquote>
                            
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">🎓✔️ International Certifications</h3>
                                    <p className="text-gray-700">We prepare our students for level-based exams conducted by globally recognized institutions such as Trinity College London and ABRSM. These certifications provide a structured progression, recognized worldwide.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">🧑‍🏫 Certified Teachers & Structured Pedagogy</h3>
                                    <p className="text-gray-700">Our faculty consists of highly qualified and certified teachers who ensure strong foundations in theory, technique, and artistry.</p>
                                </div>
                                <div className="bg-white rounded-xl shadow-md p-4">
                                    <h3 className="text-lg font-bold text-gray-800">🎶🎹 Regular Recitals for Confidence Building</h3>
                                    <p className="text-gray-700">What you learn must be celebrated! We conduct monthly and annual recitals to showcase progress and build confidence.</p>
                                </div>
                            </div>
                        </section>

                        <section className="bg-white rounded-xl shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold text-blue-600 mb-4">Programs for Kids and Adults</h2>
                            <p className="text-gray-700">
                                <strong>For Kids:</strong> Learning piano enhances cognitive skills, discipline, and creativity.<br />
                                <strong>For Adults:</strong> It's never too late to learn! Reduces stress and boosts memory.
                            </p>
                        </section>

                        <section className="text-center py-8 bg-blue-600 text-white rounded-xl shadow-lg">
                            <h2 className="text-3xl font-bold mb-4">Join Our Growing Community of Pianists</h2>
                            <p className="mb-6">At SpArts, learning piano is more than playing notes; it’s about creating harmony in life. Let’s make music together!</p>
                            <button onClick={() => navigate(Routes.academies + '?discipline=piano')} className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full shadow-md hover:bg-gray-100">Explore Academies</button>
                        </section>
                    </div>
                </body>
            </div>
            <Footer />
            <WhatsAppButton />
        </div>
    )
}

export default PianoDisciplinePage