import React, { useEffect, useRef, useState } from 'react'
import HomePageWebViewModel from '../../../view-models/home-page-web-viewmodel'
import { AnimatePresence, motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import AcademyCarousel from '../../../components/landing-page-items/academy-carousal';
import TestimonyCarousal from '../../../components/landing-page-items/testimony-carousal';
import WhatsAppButton from '../../../components/common/whatsapp-chat-button';
import HomePageDisciplineCarousal from '../../../components/common/home-page-discipline-carousal';
import { RiArrowDropDownFill } from "react-icons/ri";
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../navigation/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/redux/store';
import Navbar from '../../../components/landing-page-items/navbar';
import { Helmet } from 'react-helmet-async';
import PwaInstallPrompt from '../../../components/common/pwa-install-prompt';
import Footer from '../../../components/landing-page-items/footer';
import ActivityFinder from '../../../components/landing-page-items/activity-finder';

const SliderRow = ({ images, reverse, speed }: { images: string[]; reverse: boolean; speed: number }) => {
    const rowRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const row = rowRef.current;
      let scrollPosition = 0;
  
      const animateRow = () => {
        scrollPosition += reverse ? -speed : speed;
        if (row) {
          row.style.transform = `translateX(${scrollPosition}px)`;
  
          // Reset position when out of bounds
          if (scrollPosition > window.innerWidth || scrollPosition < -row.scrollWidth) {
            scrollPosition = reverse ? window.innerWidth : -row.scrollWidth;
          }
        }
  
        requestAnimationFrame(animateRow);
      };
  
      animateRow();
    }, [reverse, speed]);
  
    return (
      <div
        className="flex gap-1 w-full overflow-hidden whitespace-nowrap will-change-transform"
        ref={rowRef}
      >
        {[...images, ...images].map((src, idx) => (
          <div className="flex-shrink-0" key={idx}>
            <img
              className="block max-h-full h-full object-cover opacity-40"
              src={src}
              alt={`Slide ${idx}`}
            />
          </div>
        ))}
      </div>
    );
};

const HomePageWeb = () => {
    const {
        isLoading,
        rows,
        academiesToFeature,
        handleShowMoreDisciplines,
        showMoreDisciplinesModal,
        dummyData,
        activeFeaturedBlog,
        featuredBlogs,
        setActiveFeaturedBlog,
        navigate,
        user,
    } = HomePageWebViewModel();

    const [isActivityFinderOpen, setIsActivityFinderOpen] = useState(false);

    const handleToggleActivityFinder = () => {
        setIsActivityFinderOpen(prev => !prev);
    }

    return (
        <div className='max-w-screen overflow-x-hidden'>
            <Helmet>
                <title>
                     Book Sports Coaches & Academies online in Bangalore, Sports Coaching in Bangalore - SpArts
                </title>
                <meta name="description" content="SpArts: Book top sports coaches and academies online in Bangalore. Explore expert sports coaching and training for all skill levels. Start your journey today!"/>
                <link rel="canonical" href={`https://students.sparts.app`} />
            </Helmet>
            <div className='px-1 md:px-6'>
                <div className="splash-container overflow-hidden fixed top-0 left-0 z-[999999]">
                    <span className="title">S</span>
                    <span className="title">p</span>
                    <span className="title">A</span>
                    <span className="title">r</span>
                    <span className="title">t</span>
                    <span className="title">s</span>
                    <div className="absolute top-0 left-0 w-full flex flex-col h-screen overflow-hidden gap-1 bg-black scale-150 rotate-12">
                        {rows.map((row, idx) => (
                            <SliderRow
                                key={idx}
                                images={row.images}
                                reverse={row.reverse}
                                speed={row.speed}
                            />
                        ))}
                    </div>
                </div>
                {isActivityFinderOpen &&
                    <ActivityFinder
                        handleToggleActivityFinder={handleToggleActivityFinder}
                    />
                }

                <Navbar />

                <div 
                    className='w-full rounded-3xl p-4 over flex flex-col justify-center items-center py-14 relative overflow-hidden'
                    style={{
                        background: 'radial-gradient(circle, rgba(39,27,236,1) 0%, rgba(98,129,228,0.9724264705882353) 49%, rgba(0,212,255,1) 100%)',
                    }}
                >
                    <video 
                        src='/assets/videos/hero.webm' 
                        autoPlay 
                        muted 
                        loop 
                        className='absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover opacity-20'
                    />
                    <div className='text-[36px] md:text-[82px] text-white font-bold md:px-10 text-center w-full rubik-dirt-regular z-10 text-shadow-lg'>
                        <div className='shadow-black'>Empowering Future Stars in</div>
                        <span className='text-[#F9CA54]'>Sp</span>orts & <span className='text-[#F9CA54]'>Arts</span> – Your Trusted 
                        <div>Guide to Excellence!</div>
                    </div>
                    <div className='text-lg md:text-3xl text-[#C7C3C6] font-semibold mt-4 cursor-pointer z-10 slide-animated-text'><a href='#service-explanation'>Discover How We Do It</a></div>
                    <div className='p-4 md:p-8 mt-4 md:mt-8 flex flex-col md:flex-row items-center gap-4 md:gap-8 z-10'>
                        {/* <button className='py-2 shadow-lg hover:scale-110 md:py-3 px-4 md:px-8 rounded-full text-lg md:text-3xl bg-[#4285f4] flex items-center gap-2 md:gap-4 font-semibold text-white active:scale-95 transition-all'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-6 md:h-8" viewBox="0 0 512 512">
                                <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                            </svg>
                            Download SpArts
                        </button> */}
                        <button onClick={() => navigate(Routes.academies)} className='py-2 shadow-lg hover:scale-110 md:py-3 px-4 md:px-8 rounded-full text-lg md:text-3xl bg-[#F9CA54] text-white font-semibold active:scale-95 transition-all'>Find the best academies</button>
                    </div>
                </div>

                <section className="px-4 py-8 md:px-16 md:py-24 bg-[#F9CA54] my-8 rounded-3xl border-[6px] border-slate-200 relative overflow-hidden">
                    <div className="container px-2 md:px-6 relative z-[10]">
                        <h2 className="text-[32px] md:text-[48px] font-bold text-white">
                            What Do You Want To Do Today?
                        </h2>
                        <p className="text-slate-50 text-xl mb-10 w-full md:w-[50%]">
                            Discover academies near you or get personalized counseling to find the best discipline for your child.
                        </p>
                        <div className="flex flex-col md:flex-row gap-6">
                            <div className='w-[280px] h-[280px] md:h-[400px] md:w-[400px] relative'>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '14px',
                                    zIndex: 1111,
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '5px 5px 20px #ffdb6e, -5px -5px 20px #f2faff',
                                }}>
                                    <div 
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            left: '5px',
                                            zIndex: 2,
                                            background: 'rgba(255, 255, 255, 0.95)',
                                            backdropFilter: 'blur(24px)',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            outline: '2px solid white',
                                        }}
                                        className='h-[270px] w-[270px] md:h-[390px] md:w-[390px]'
                                    >
                                        <div className='h-full w-full rounded shadow-lg bg-black/5'>
                                            <img 
                                                src='https://i.pinimg.com/736x/5a/ac/fc/5aacfc55f14edd232224c73acad8e80e.jpg' 
                                                className='w-full h-full object-cover'
                                                alt='counseling'
                                                loading='lazy'
                                            />
                                        </div>
                                        <div 
                                            onClick={handleToggleActivityFinder}
                                            className="text-center cursor-pointer absolute m-4 bottom-0 left-0 right-0 inline-block w-auto px-4 py-2 md:px-8 md:py-4 text-sm md:text-lg font-medium text-white bg-green-500 hover:bg-green-600 rounded-lg shadow-md transition">
                                            Start Counseling
                                        </div>
                                    </div>
                                    <div 
                                        style={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            top: '50%',
                                            left: '50%',
                                            width: '300px',
                                            height: '300px',
                                            borderRadius: '50%',
                                            backgroundColor: '#62a6ea',
                                            opacity: 1,
                                            filter: 'blur(12px)',
                                            animation: 'blob-bounce 5s infinite ease',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        className='h-[270px] w-[270px] md:h-[390px] md:w-[390px]'
                                    ></div>
                                </div>
                            </div>
                            <div className='w-[280px] h-[280px] md:h-[400px] md:w-[400px] relative'>
                                <div className='h-full w-full rounded-xl shadow-lg bg-black/5 overflow-hidden'>
                                    <img 
                                        src='https://i.pinimg.com/736x/09/4c/19/094c1941a45821198c746850b2c477c3.jpg'
                                        className='h-full w-full object-cover'
                                        alt='find academies'
                                        loading='lazy'
                                    />
                                </div>
                                <div
                                    onClick={() => navigate(Routes.academies)}
                                    className="text-center absolute m-4 bottom-0 left-0 right-0 inline-block w-auto px-4 py-2 md:px-8 md:py-4 text-sm md:text-lg font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-lg shadow-md transition">
                                    Find Academies Near You
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-[1050px] w-[1050px] rounded-full shadow-lg bg-[#fdcf5b] absolute top-0 bottom-0 right-0 my-auto translate-x-1/2 flex items-center justify-center'>
                        <div className='h-[900px] w-[900px] rounded-full shadow-lg bg-[#fbd36e] flex items-center justify-center'>
                            <div className='h-[750px] w-[750px] rounded-full shadow-lg bg-[#fddc89] flex items-center justify-center'>
                                <div className='h-[600px] w-[600px] rounded-full shadow-lg bg-[#ffe29a] flex items-center justify-center'>
                                    <div className='h-[450px] w-[450px] rounded-full shadow-lg bg-[#f8e7bd] flex items-center justify-center'>
                                        <div className='h-[300px] w-[300px] rounded-full shadow-lg bg-[#f6ecd4] flex items-center justify-center'>
                                            <div className='h-[150px] w-[150px] rounded-full shadow-lg bg-white flex items-center justify-center hover:rotate-[360deg] transition-all duration-700 delay-100'>
                                                <img src='/favicon.svg' className='h-full w-full p-10' alt='sparts' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto md:px-6 text-center">
                        <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-6">
                            Explore Disciplines
                        </h2>
                        <p className="text-gray-600 text-lg mb-8">
                            Choose a discipline to find academies that can help you excel in your area of interest.
                        </p>
                        <div className="relative">
                            <HomePageDisciplineCarousal />
                        </div>
                    </div>
                </section>

                <hr className='my-8 mx-[30%]'/>

                <div id='service-explanation' className='p-4 py-8 rounded-lg overflow-hidden my-8 relative'>
                    <div className='text-2xl md:text-4xl font-semibold text-center mt-4'>Empowering Excellence Through Trusted Academies and Proven Opportunities</div>
                    <div className='text-sm md:text-xl font-semibold text-slate-400 my-2 md:my-4 text-center'>From choosing the right academy to celebrating milestones, we prioritize your child’s growth, confidence, and success.</div>
                    <div className='flex flex-col gap-8 md:gap-20 p-6'>
                        <div className='my-4 flex flex-col gap-3 relative translate-x-[-10%] md:translate-x-0'>
                            <div className='flex items-center justify-center gap-4'>
                                <div className='flex items-center justify-center w-[90%] md:w-1/2 bg-[#F9CA54] rounded-lg'>
                                    <div className='p-4 rounded-lg w-full z-10'>
                                        <div className='text-lg md:text-2xl font-semibold'>Discover Top-Rated, Certified Academies</div>
                                        <div className='text-[#2f2f2f] text-sm md:text-lg mt-3'>We partner exclusively with academies that meet our rigorous standards. Every academy on our platform has certified coaches dedicated to providing a structured, high-quality learning environment.</div>
                                    </div>
                                </div>
                                <div className='text-xl font-semibold h-full flex items-center justify-center relative'>
                                    <div className='absolute top-0 left-0 bottom-0 flex items-center justify-center text-slate-200 text-center text-[120px] md:text-[180px] font-semibold scale-150'>1</div>
                                    {/* <div className='z-10'>No compromises, no distractions – just focused, meaningful learning.</div> */}
                                </div>
                            </div>
                        </div>
                        <div className='my-4 flex flex-col gap-3 relative translate-x-[10%] md:translate-x-0'>
                            <div className='flex justify-center gap-4 items-center'>
                                <div className='text-xl font-semibold relative h-full flex items-center justify-center'>
                                    <div className='absolute top-0 right-0 bottom-0 flex items-center justify-center text-slate-200 text-center text-[120px] md:text-[180px] font-semibold scale-150'>2</div>
                                    {/* <div className='text-right z-10'>From local tournaments to showcase performances, every event is designed to add value and help students shine.</div> */}
                                </div>
                                <div className='flex items-center justify-center w-[90%] md:w-1/2 bg-[#F9CA54] rounded-lg'>
                                    <div className='p-4 rounded-lg w-full z-10'>
                                        <div className='text-lg md:text-2xl font-semibold'>Competitions and Events That Matter</div>
                                        <div className='text-[#2f2f2f] text-sm md:text-lg mt-3'>Our academies regularly host events and competitions that are more than just activities – they’re opportunities to showcase skills and build confidence.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-4 flex flex-col gap-3 relative translate-x-[-10%] md:translate-x-0'>
                            <div className='flex justify-center gap-4 items-center'>
                                <div className='flex items-center justify-center w-[90%] md:w-1/2 bg-[#F9CA54] rounded-lg'>
                                    <div className='p-4 rounded-lg w-full z-10'>
                                        <div className='text-lg md:text-2xl font-semibold'>Focused on Your Child’s Growth</div>
                                        <div className='text-[#2f2f2f] text-sm md:text-lg mt-3'>At the heart of every academy is a commitment to your child’s success. Their number one priority is nurturing skills, building discipline, and instilling confidence.</div>
                                    </div>
                                </div>
                                <div className='text-xl font-semibold relative h-full flex items-center justify-center'>
                                    <div className='absolute top-0 left-0 bottom-0 flex items-center justify-center text-slate-200 text-center text-[120px] md:text-[180px] font-semibold scale-150'>3</div>
                                    {/* <div className='z-10'>We ensure every academy creates a supportive and inspiring environment, giving students the tools to thrive.</div> */}
                                </div>
                            </div>
                        </div>
                        <div className='my-4 flex flex-col gap-3 relative translate-x-[10%] md:translate-x-0'>
                            <div className='flex justify-center gap-4 items-center'>
                                <div className='text-xl font-semibold relative h-full flex items-center justify-center'>
                                    <div className='absolute top-0 right-0 bottom-0 flex items-center justify-center text-slate-200 text-center text-[120px] md:text-[180px] font-semibold scale-150'>4</div>
                                    {/* <div className='text-right z-10'>Transparency and accountability ensure you’re always in the loop.</div> */}
                                </div>
                                <div className='flex items-center justify-center w-[90%] md:w-1/2 bg-[#F9CA54] rounded-lg'>
                                    <div className='p-4 rounded-lg w-full z-10'>
                                        <div className='text-lg md:text-2xl font-semibold'>Track Progress Every Step of the Way</div>
                                        <div className='text-[#2f2f2f] text-sm md:text-lg mt-3'>Our app makes it easy to stay involved in your child’s journey. Track milestones, review progress reports, and celebrate achievements – all in one place.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='my-4 bg-[#020a17] w-full rounded-lg p-4 md:p-8'>
                    <div className='text-slate-50 text-2xl md:text-4xl font-semibold text-center'>Why Choose Us?</div>
                    <div className='text-slate-50 text-lg md:text-2xl font-semibold text-center mt-2'>Save <span className='text-[#F9CA54]'>Time</span>, Reduce <span className='text-[#F9CA54]'>Hassle</span>, and Ensure Your Child’s <span className='text-[#F9CA54]'>Long-Term Growth</span></div>
                    <div className='text-slate-300 text-sm md:text-lg font-semibold text-center mt-6 md:mt-10'>We simplify the journey to finding the perfect academy while ensuring your child stays engaged and motivated.</div>
                    <hr className='md:mx-40 my-10 border-slate-700'/>
                    <div className='px-2 md:px-20'>
                        <div className='text-lg sm:text-2xl font-semibold text-white'>Save Hours of Research and Trial</div>
                        <div className='text-sm md:text-lg text-slate-300 mt-2'>With our carefully curated list of certified academies, you no longer have to spend hours comparing options. Our recommendations help you find the right academy quickly and easily.</div>
                        <div className='text-sm md:text-lg text-slate-400 font-semibold my-2 md:my-6 mt-10 md:mt-16'>Parents save up to <span className='text-[28px] md:text-[40px] text-[#F9CA54] px-2'>4 - 6 hours</span> by selecting academies through our platform.</div>
                        <div className='p-2 md:p-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='flex flex-col items-center justify-center gap-2'>
                                <div className='flex items-center gap-4 w-full justify-center'>
                                    <div className='text-sm md:text-lg font-semibold w-40 text-right text-red-400'>4 - 6 hours</div>
                                    <div className='h-6 md:h-8 rounded-md bg-red-500 w-full'></div>
                                </div>
                                <div className='text-red-400 font-semibold mt-1 text-sm md:text-lg'>Traditional Approach</div>
                            </div>
                            <div className='flex flex-col items-center justify-center gap-2'>
                                <div className='flex items-center gap-4 w-full justify-center'>
                                    <div className='text-sm md:text-lg text-slate-300 font-semibold w-40 text-right'>10 - 20 Minutes</div>
                                    <div className='w-full relative'>
                                        <div className='h-6 md:h-8 rounded-md bg-slate-500 w-full absolute z-0'></div>
                                        <div className='h-6 md:h-8 rounded-md bg-[#4285f4] w-[3%] relative z-10'></div>
                                    </div>
                                </div>
                                <div className='text-[#4285f4] font-semibold mt-1 text-sm md:text-lg'>Using SpArts</div>
                            </div>
                        </div>
                    </div>
                    <hr className='md:mx-40 my-10 border-slate-700'/>
                    <div className='px-2 md:px-20'>
                        <div className='text-lg sm:text-2xl font-semibold text-white'>Personalized Recommendations That Keep Kids Engaged</div>
                        <div className='text-sm md:text-lg text-slate-300 mt-2'>Our counseling process ensures your child is matched to a sport or art based on their interests and preferences. This helps prevent frustration or boredom after just a few classes, ensuring they stay excited about their journey.</div>
                        <div className='text-sm md:text-lg text-slate-400 font-semibold my-2 md:my-6 mt-10 md:mt-16'>Parents report a <span className='text-[28px] md:text-[40px] text-[#F9CA54] px-2'>90% satisfaction rate</span> with their child’s recommended sport or art through our platform.</div>
                        <div className='p-4 flex flex-col gap-4 md:pl-20'>
                            <div className='text-sm md:text-lg text-slate-300 font-semibold'><span className='text-[26px] md:text-[32px]'>150+</span> Academies registered with SpArts and more adding every day</div>
                            <div className='text-sm md:text-lg text-slate-300 font-semibold'>with over <span className='text-[26px] md:text-[32px]'>120+</span> different disciplines</div>
                        </div>
                    </div>
                    <hr className='md:mx-40 my-10 border-slate-700'/>
                    <div className='px-2 md:px-20'>
                        <div className='text-lg md:text-2xl font-semibold text-white'>Avoid the Search Loop</div>
                        <div className='text-sm md:text-lg text-slate-300 mt-2'>By connecting with academies that prioritize structured growth and regular feedback, we ensure parents aren’t stuck in a never-ending cycle of searching for a new academy.</div>
                        <div className='text-sm md:text-lg text-slate-400 font-semibold my-2 md:my-6 mt-10 md:mt-16'><span className='text-[28px] md:text-[40px] text-[#F9CA54] pr-2'>80%</span> of parents find the right academy on the first try using our platform.</div>
                        <div className='p-4 grid grid-cols-3 gap-4'>
                            <div className="col-span-3 md:col-span-1 relative w-56 h-56 md:w-72 md:h-72 mx-auto border-4 border-red-500 border-opacity-60 rounded-full m-20 order-1 scale-90 md:scale-100">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 text-sm md:text-lg md:w-32 md:h-32 bg-[#020a17] text-white border-2 border-red-500 border-opacity-60 font-semibold p-4 text-center flex items-center justify-center rounded-full shadow-lg">
                                    Find an Academy
                                </div>

                                <div className="absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 w-24 h-24 text-sm md:text-lg md:w-32 md:h-32 bg-[#020a17] p-4 text-center border-red-500 border-opacity-60 font-semibold border-2 text-white flex items-center justify-center rounded-full shadow-lg">
                                    Register
                                </div>

                                <div className="absolute bottom-0 right-1/2 transform translate-x-1/2 translate-y-1/2 w-24 h-24 text-sm md:text-lg md:w-32 md:h-32 bg-[#020a17] text-center p-4 border-red-500 border-opacity-60 font-semibold border-2 text-white flex items-center justify-center rounded-full shadow-lg">
                                    Attend Class
                                </div>

                                <div className="absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 text-sm md:text-lg md:w-32 md:h-32 bg-[#020a17] text-center p-4 border-red-500 border-opacity-60 font-semibold border-2 text-white flex items-center justify-center rounded-full shadow-lg">
                                    Not Satisfied?
                                </div>
                            </div>
                            <div className='flex items-center gap-4 md:gap-10 col-span-3 md:col-span-2 justify-center relative h-[200px] md:h-auto order-3 md:order-2'>
                                <div className='z-10 rounded-md bg-[#4285f4] text-xs text-nowrap md:text-sm px-2 md:px-4 py-1 text-white font-semibold hover:scale-110 transition-all cursor-pointer translate-y-[80px]'>Install SpArts</div>
                                <div className='z-10 rounded-md bg-orange-300 text-xs text-nowrap md:text-sm px-2 md:px-4 py-1 font-semibold translate-y-[40px]'>Search</div>
                                <div className='z-10 rounded-md bg-orange-300 text-xs text-nowrap md:text-sm px-2 md:px-4 py-1 font-semibold '>Demo Class</div>
                                <div className='z-10 rounded-md bg-orange-300 text-xs text-nowrap md:text-sm px-2 md:px-4 py-1 font-semibold translate-y-[-40px]'>Enroll</div>
                                <div className='z-10 rounded-md bg-green-300 text-xs text-nowrap md:text-sm px-2 md:px-4 py-1 font-semibold translate-y-[-80px]'>Progress</div>
                                <img alt='arrow' src='/assets/images/growth-arrow.svg' className='w-full h-full absolute scale-75 opacity-60'/>
                            </div>
                            <div className='text-red-400 col-span-3 md:col-span-1 font-semibold text-xl text-center order-2 md:order-3'>Without SpArts</div>
                            <div className='col-span-3 md:col-span-2 text-[#4285f4] font-semibold text-xl text-center order-4 md:order-4 '>With SpArts</div>
                        </div>
                    </div>
                </div>

                {featuredBlogs?.length > 0 && (
                    <div className='my-20 w-full md:px-20'>
                        <div className='text-center text-xl md:text-2xl font-semibold'>Why Parents Trust Us with Their Children's Future</div>
                        <div className='text-center text-sm md:text-lg'>Dive into our expert insights – explore the lesser-known facts about sports and arts to make informed decisions.</div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-12 overflow-hidden md:h-[480px] items-center'>
                            <div className='h-[300px] md:h-[340px] w-full rounded-3xl bg-[#4285f4]'>
                                <img 
                                    src={featuredBlogs[activeFeaturedBlog]?.attributes?.previewImage?.data?.attributes?.url}
                                    className='h-full w-full object-cover rounded-3xl'
                                    loading='lazy'
                                    alt='blog'
                                />
                            </div>
                            <div className='h-[380px] overflow-hidden flex gap-10 bg-[#E7EFFF] rounded-3xl px-4'>
                                <div className='flex-grow p-4 py-8 flex flex-col'>
                                    <div className='text-xl md:text-3xl font-semibold'>{featuredBlogs[activeFeaturedBlog]?.attributes?.title}</div>
                                    <div className='mt-3 line-clamp-4'>{featuredBlogs[activeFeaturedBlog]?.attributes?.description}</div>
                                    <div className='flex-grow flex flex-col justify-end items-center gap-4'>
                                        <button onClick={() => navigate(Routes.blog + '/' + featuredBlogs[activeFeaturedBlog]?.attributes?.pageTitle?.replace(/\s+/g, '-'))} className='md:py-3 py-2 px-4 md:px-8 rounded-full bg-[#4285f4] font-semibold text-white active:scale-95 transition-all'>Check out blog</button>
                                        {/* <button className='md:py-3 py-2 px-4 md:px-8 rounded-full bg-[#ffffff] active:scale-95 transition-all'>
                                            <img 
                                                src='/assets/images/youtube.svg'
                                                className='h-6 p-1'
                                                alt='youtube'
                                            />
                                        </button> */}
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 overflow-y-auto py-4 min-w-[180px] md:min-w-[220px] '>
                                    {featuredBlogs.map((item, idx) => (
                                        <div onClick={() => setActiveFeaturedBlog(idx)} key={idx} className={`h-[110px] md:h-[140px] w-[180px] md:w-[220px] bg-slate-500 flex-shrink-0 rounded-lg cursor-pointer ${activeFeaturedBlog === idx ? 'border-4 border-[#4285f4]' : 'opacity-80 scale-90'} transition-all`}>
                                            <img 
                                                src={item?.attributes?.previewImage?.data?.attributes?.url}
                                                className='h-full w-full object-cover rounded-lg'
                                                loading='lazy'
                                                alt='blog'
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='my-4 p-6'>
                    <TestimonyCarousal />
                </div>

                <div className='p-10 bg-[#E7EFFF] rounded-3xl my-10'>
                    <div className='text-2xl md:text-4xl font-semibold '>Explore Our Trusted Academy Partners</div>
                    <div className='text-slate-500 text-sm md:text-lg font-semibold mt-1 md:mt-2'>Join hands with the best in sports and arts, handpicked for their excellence and commitment to your child's growth.</div>
                    <div className='py-4 md:p-14'>
                        <AcademyCarousel
                            academies={academiesToFeature}
                            handleShowMoreDisciplines={handleShowMoreDisciplines}
                            showMoreDisciplinesModal={showMoreDisciplinesModal}
                        />
                    </div>
                </div>

                {/* <div className='bg-[#F9CA54] rounded-3xl px-4 pt-12 md:pt-40 my-4 relative overflow-hidden'>
                    <svg className='absolute top-0 left-0 w-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="#4285f4"><path d="M0 0v99.7C62 69 122.4 48.7 205 66c83.8 17.6 160.5 20.4 240-12 54-22 110-26 173-10a392.2 392.2 0 0 0 222-5c55-17 110.3-36.9 160-27.2V0H0Z" opacity=".5"></path><path d="M0 0v74.7C62 44 122.4 28.7 205 46c83.8 17.6 160.5 25.4 240-7 54-22 110-21 173-5 76.5 19.4 146.5 23.3 222 0 55-17 110.3-31.9 160-22.2V0H0Z"></path></svg>
                    <div className='text-2xl md:text-4xl font-semibold text-center'>All-in-One App for a Hassle-Free Learning Journey</div>
                    <div className='text-slate-700 text-center text-sm md:text-lg font-semibold mt-2'>From discovering academies to enhancing skills at home, our app is designed to simplify every step of your child’s growth.</div>
                    <div className='p-2 md:p-8 grid grid-cols-4 md:grid-cols-2 items-center justify-center gap-x-2 md:gap-x-6 md:px-48 pb-0'>
                        <div className='col-span-3 md:col-span-1'>
                            <div className=' p-2 py-3 border-opacity-80 flex flex-row-reverse items-center gap-4'>
                                <img alt='search' src='/assets/images/search-illustration.webp' className='w-[44px] md:w-[58px] ' />
                                <div className=''>
                                    <div className='text-sm text-right md:text-lg font-semibold'>Explore Academies and Book Demo Classes</div>
                                </div>
                            </div>
                            
                            <div className=' p-2 py-3 border-opacity-80 flex flex-row-reverse items-center gap-4'>
                                <img alt='list' src='/assets/images/list-illustration.webp' className='w-[44px] md:w-[58px] ' />
                                <div>
                                    <div className='text-sm text-right md:text-lg font-semibold'>View Attendance and Track Assignments</div>
                                </div>
                            </div>
                            <div className='p-2 py-3 border-opacity-80 flex flex-row-reverse items-center gap-4 md:ml-20'>
                                <img alt='notification' src='/assets/images/notification-illustration.webp' className='w-[44px] md:w-[58px] ' />
                                <div>
                                    <div className='text-sm text-right md:text-lg font-semibold'>Instant Notifications and Updates</div>
                                </div>
                            </div>
                            <div className=' p-2 py-3 border-opacity-80 flex flex-row-reverse items-center gap-4'>
                                <img alt='ai trainer' src='/assets/images/ai-illustration.webp' className='w-[44px] md:w-[58px]  rounded-xl' />
                                <div>
                                    <div className='text-sm text-right md:text-lg font-semibold'>AI Trainer for Basic Exercises</div>
                                </div>
                            </div>
                            <div className='p-2 py-3 border-opacity-80 flex flex-row-reverse items-center gap-4 md:ml-20'>
                                <img alt='video' src='/assets/images/video-illustration.webp' className='w-[44px] md:w-[58px] ' />
                                <div>
                                    <div className='text-sm text-right md:text-lg font-semibold'>Attend Online Classes</div>
                                </div>
                            </div>
                            <div className='p-2 py-3 border-opacity-80 flex flex-row-reverse items-center gap-4 md:ml-20'>
                                <img alt='payment' src='/assets/images/payment-illustration.webp' className='w-[44px] md:w-[58px]  rounded-2xl' />
                                <div>
                                    <div className='text-sm text-right md:text-lg font-semibold'>Pay Fees Securely</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img 
                                src='/assets/images/app-portrait.webp'
                                className='w-[280px] object-cover scale-150 md:scale-100 translate-x-[50%] md:translate-x-0'
                                alt='app'
                            />
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className='p-8 flex flex-col items-center'>
                            <div className='text-2xl md:text-5xl font-bold w-[400px] text-center'>Try it right now</div>
                            <div className='mt-2 md:mt-4 font-semibold w-[400px] text-center text-sm md:text-lg'>Download the SpArts app on google play and discover the power of SpArts! Enjoy a personalized learning experience with the SpArts app.</div>
                            <div className='mt-4 w-[400px] flex justify-center'>
                                <img 
                                    src='/assets/images/playstore.webp'
                                    className='h-[45px] cursor-pointer'
                                    alt='playstore'
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


            <Footer />
            <WhatsAppButton />
            {/* <PwaInstallPrompt delay={8}/> */}
        </div>
    )
}

export default HomePageWeb